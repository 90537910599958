@font-face {
  font-family: 'Red Hat Display';
  src: local('arial-light'),
    url('../assets/font/RedHatDisplay-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Red Hat Display Bold';
  src: local('Red Hat Display Bold'),
    url('../assets/font/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Red Hat Display Medium';
  src: local('Red Hat Display Medium'),
    url('../assets/font/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Red Hat Display SemiBold';
  src: local('Red Hat Display SemiBold'),
    url('../assets/font/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Red Hat Display Regular';
  src: local('Red Hat Display Regular'),
    url('../assets/font/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
}
#Upload_Now {
  font-family: 'Arial-Regular';
}
body,
div,
span,
label,
input,
textarea,
button,
select,
option,
td,
th,
li,
p {
  font-family: Red Hat Display Regular !important;
}
/* Works on Chrome, Edge, and Safari */
/* width */
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bec4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
body {
  font-size: 0.9em !important;
  background-color: #f8f8fa !important;
}
.w-13 {
  width: 13%;
}
.w-14 {
  width: 14%;
}
.sso-app {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 135px;
  border: 1px solid gray;
  text-align: center;
  margin-right: 14px;
  cursor: pointer;
  font-size: 15px !important;
  font-family: Red Hat Display SemiBold !important;
  text-decoration: none !important;
}
.left {
  border-radius: 12px 0px 0px 12px;
  margin-right: unset;
}
.active-sso-portal {
  background-color: #5fb56a;
  color: #ffff !important;
  cursor: not-allowed;
}
.rigth {
  border-radius: 0px 12px 12px 0px;
}

.main-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  .f-65 {
    font-size: 65px;
  }
  .denied {
    font-size: 35px;
    font-weight: 800;
  }
  .f-18 {
    font-size: 18px;
  }
  .back-to {
    font-size: 16px;
    display: flex;
    gap: 11px;
    margin-top: 18px;
    .login {
      cursor: pointer;
      font-weight: 700;
      color: red;
    }
  }
}
.upload-btn {
  position: relative;

  img {
    position: absolute;
    right: 12px;
    top: 15px;
  }
  label {
    font-family: 'Red Hat Display Medium' !important;
    color: #241a2e;
    font-size: 14px !important;
  }
}
.view-resume {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
  .notes-box {
    height: 74vh;
    overflow-y: auto;
  }
  .notes-item {
    border-radius: 3px;
    background-color: #e2e2e2;
    min-height: 80px;
    padding: 13px 12px;
    margin-bottom: 12px !important;
    p {
      color: #727272 !important;
      font-size: 12px !important;
      letter-spacing: 0;
      line-height: 16px !important;
    }
    .spanNotes {
      color: #181717 !important;
      font-size: 12px !important;
      text-transform: capitalize !important;
      letter-spacing: 0;
      line-height: 16px !important;
      margin-top: 8px !important;
    }
  }
  .add-m-title {
    color: #000000;
    font-size: 24px !important;
    letter-spacing: 0;
    line-height: 22px;
    font-family: Red Hat Display SemiBold !important;
  }
  .m-blue-btn {
    height: 50px !important;
    min-width: 84px !important;
    border-radius: 8px !important;
    font-size: 17px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
    line-height: 22px;
    font-family: Red Hat Display Bold !important;
    background-color: #0a84f5 !important;
    padding: 0 15px;
  }
}
.back-nav-link {
  display: flex;
  align-items: center;
  a {
    font-family: 'Red Hat Display Bold';
    color: #005ebf;
    font-size: 12px;
  }
}

.resume-filter-btn {
  // height: 50px !important;
  // // height: 29px !important;
  // min-width: 152px !important;
  // min-width: 84px !important;
  // // border-radius: 8px !important;
  // font-size: 17px !important;
  // color: #ffffff !important; /* Text color */
  // text-transform: capitalize !important;
  // line-height: 22px;
  // font-family: Red Hat Display Bold !important;
  // background-color: #bf0000 !important; /* Background color */
  // padding: 0 15px;

  background-color: #bf0000 !important;
  border-radius: 4px !important;
  height: 29px !important;
  // width: 152px !important;
  // width: 90px !important;
  width: 130px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  line-height: 18.52px !important;
  font-family: Red Hat Display;
  // text-decoration: unset !important;
  text-transform: none !important;
  // padding: 5px 60px 5px 60px !important;
  // padding: 2px 10px 2px 10px !important;
}

.resume-filter-centered-button-container {
  display: flex;
  justify-content: space-evenly;
  // column-gap: 52%;
  align-items: center;
  // height: 90vh; /* Adjust as needed */
  margin-top: 0.5rem;
  // column-gap: 25% !important;
  // column-gap: 6% !important;
}

// .rdrNextPrevButton {
//   margin: auto !important;
// }
// .rdrMonths {
//   align-items: center !important;
// }

// .date-range-container {
//   display: flex !important;
//   flex-direction: column !important;
//   width: 100% !important;
// }

.rdrMonthAndYearWrapper {
  // margin-left: -6px !important;
  margin-left: -7px !important;
  // margin-right: -6px !important;
}

.chip-status-green {
  background-color: #1d9900 !important;
  padding: 8px 2px !important;
  height: 24px !important;
  // position: absolute;
  // right: 0;
  // top: 21px;

  img {
    width: 10px;
    height: 10px;
    top: 0 !important;
  }

  span {
    font-family: 'Red Hat Display SemiBold';
    font-size: 12px;
    text-transform: uppercase;
  }
}
.MuiToolbar-root {
  min-height: 60px !important;
  padding-left: 20px !important;
  padding-right: 5.5px !important;
}
textarea {
  background-color: #f8f8fa !important;
  padding: 10px !important;
  height: 100px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px;
  box-sizing: border-box !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 14px !important;
  color: #292929 !important;

  &:focus-visible {
    border: 1px solid #e0e0e0 !important;
  }
  &:active {
    border: 1px solid #e0e0e0 !important;
  }
  &:hover {
    border: 1px solid #e0e0e0 !important;
  }
}
textarea,
input:focus {
  outline: none !important;
}

input {
  background-color: #f8f8fa !important;
  padding: 0 10px !important;
  height: 40px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px;
  box-sizing: border-box !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 16px !important;
  color: #292929 !important;

  &:hover {
    border: 1px solid #e0e0e0 !important;
  }
}

label {
  font-family: 'Red Hat Display bold';
  font-size: 14px;
  color: #292929;
  line-height: 30px;
  margin: 0px;
}

textarea,
input:focus {
  outline: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
}

.default-chexbox {
  .MuiCheckbox-colorPrimary {
    color: #e0e0e0;
  }

  .Mui-checked {
    color: #bf0000 !important;
  }

  span {
    font-family: 'Red Hat Display SemiBold';
    color: #292929;
    font-size: 14px;
    line-height: 18px;
  }
}

.liberty-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  justify-content: center;
  align-items: center;
  // background: url('../assets/images/loading-spinner.gif') 50% 50% no-repeat
  //   rgb(0 0 0 / 32%);
}
.active > li {
  background-color: #2a5686 !important;
  &:hover {
    background-color: #2a5686 !important;
  }
}

.siderbg {
  background-color: red;
}

.sidebar-inner {
  margin: 30px !important;
  width: 156px !important;
  img {
    margin: 30px 35px 35px 35px;
    display: table;
  }
}

.sidebar-menu {
  padding-left: 14px !important;
  padding-right: 13px !important;
  .MuiListItemButton-root {
    min-height: 46px;
    margin-bottom: 4px;
  }
  li {
    span {
      font-family: 'Red Hat Display Medium' !important;
      color: #656565;
      font-size: 14px;
    }
  }
}

.sidebar-toggle {
  button {
    margin: 0 auto;
    color: #ffffff;
    opacity: 0.4;
    position: relative;
    left: -5px;
  }
}

.recrutor-title {
  color: #000000;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 24px !important;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 26px !important;
}
.candidate-name {
  color: #000000;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 18px !important;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 26px !important;
}
.jobpost-table {
  &.MuiTableContainer-root {
    box-shadow: unset !important;
  }
  table {
    th {
      .MuiSelect-select {
        width: calc(100vw - 88vw);
      }
    }
    .w-10 {
      width: 10%;
    }
    .w-25 {
      width: 25%;
    }
    .w-15 {
      width: 15%;
    }
    .w-5 {
      width: 5%;
    }
    .w-19 {
      width: 19%;
    }
    .w-18 {
      width: 18%;
    }
    .w-13 {
      width: 13%;
    }
    .w-22 {
      width: 22%;
    }
    .w-20 {
      width: 20%;
    }
    .w-24 {
      width: 24%;
    }
    .logo-avator {
      height: 50px;
      width: 50px;
      color: #ffffff !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 18px !important;
      line-height: 23px !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 12px !important;
    }
    .match-badge {
      height: 24px;
      width: 75px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(3, 35, 189, 0.25);
      padding: 0 8px;
      color: #0323bd !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .post-badge {
      height: 24px;
      width: 75px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgb(247 224 225);
      padding: 0 8px;
      color: #ef6f75 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .tick-badge {
      img {
        margin-right: 2px !important;
      }
      height: 24px;
      width: 83px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(128, 189, 1, 0.25);
      padding: 0 8px;
      color: #80bd03 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .MuiTableCell-head {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: 'Red Hat Display Bold' !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
      padding: 12px 8px;
    }
    .th-title {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: 'Red Hat Display Bold' !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
    }
    .MuiTableCell-body {
      color: #000000 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      padding: 4.45px 8px !important;
      border-bottom: 1px solid #ebebeb !important;
      word-break: break-all;
    }
    .td-text {
      color: #000000 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      margin-left: 10px !important;
    }
    .td-norecord {
      color: #000000 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      margin-left: 10px !important;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
  }
}
.candidate-table {
  &.MuiTableContainer-root {
    box-shadow: unset !important;
  }
  table {
    .w-35 {
      width: 35%;
    }
    .w-19 {
      width: 19%;
    }
    .w-12 {
      width: 12%;
    }
    .w-14 {
      width: 14%;
    }
    .w-10 {
      width: 10%;
    }
    .w-25 {
      width: 25%;
    }
    .w-15 {
      width: 15%;
    }
    .w-5 {
      width: 5%;
    }
    .w-20 {
      width: 20%;
    }
    .w-17 {
      width: 17%;
    }
    .w-24 {
      width: 24%;
    }
    .w-4 {
      width: 4%;
    }
    .w2p-badge {
      height: 24px;
      min-width: 47px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: #f3b6b9 !important;
      padding: 0 8px;
      color: #b61f28 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
      margin-left: 6px !important;
      box-shadow: unset !important;
      border: unset !important;
      cursor: pointer;
    }
    .w2a-badge {
      height: 24px;
      min-width: 45px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(128, 189, 1, 0.25);
      padding: 0 8px;
      color: #80bd03 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
      margin-left: 6px !important;
      box-shadow: unset !important;
      border: unset !important;
      cursor: pointer;
    }
    .w20a-badge {
      height: 24px;
      min-width: 57px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(3, 35, 189, 0.25);
      padding: 0 8px;
      color: #0323bd !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
      margin-left: 6px !important;
      box-shadow: unset !important;
      border: unset !important;
      cursor: pointer;
    }
    .MuiTableCell-head {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: 'Red Hat Display Bold' !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
      padding: 12px 20px;
    }
    .th-title {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: 'Red Hat Display Bold' !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
    }
    .MuiTableCell-body {
      color: #000000 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      padding: 7.8px 5px 7.8px 20px !important;
      border-bottom: 1px solid #ebebeb !important;
      word-break: break-all;
    }
    .td-text {
      color: #000000 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
    }
  }
}
.sort-box {
  position: relative;
  padding-left: 4px;
  .up-arrow {
    position: absolute;
    top: 0;
    width: 6px;
    cursor: pointer;
  }
  .down-arrow {
    position: absolute;
    bottom: 2px;
    width: 6px;
    cursor: pointer;
  }
}
.recruiter-table {
  &.MuiTableContainer-root {
    box-shadow: unset !important;
  }
  table {
    .w-10 {
      width: 10%;
    }
    .w-25 {
      width: 25%;
    }
    .w-15 {
      width: 15%;
    }
    .w-12 {
      width: 12%;
    }
    .w-24 {
      width: 24%;
    }
    .w-5 {
      width: 5%;
    }
    .w-19 {
      width: 19%;
    }
    .w-6 {
      width: 6%;
    }
    .w-18 {
      width: 18%;
    }
    .w-2 {
      width: 3%;
    }
    .w-20 {
      width: 20%;
    }

    .w-24 {
      width: 24%;
    }
    .w-13 {
      width: 13%;
    }
    .w-14 {
      width: 14%;
    }
    .td-check {
      color: #979797 !important;
      &.Mui-checked {
        color: #80bd03 !important;
      }
    }
    .mark-avator {
      height: 40px;
      width: 40px;
      border-radius: 25px;
      background-color: #9857da;
      color: #ffffff !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 18px !important;
      margin-left: 20px !important;
      line-height: 23px !important;
    }
    .th-avator {
      height: 40px;
      width: 40px;
      border-radius: 25px;
      background-color: #3966b8;
      color: #ffffff !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 18px !important;
      line-height: 23px !important;
      margin-left: 20px !important;
    }
    .logo-avator {
      height: 50px;
      width: 50px;
      color: #ffffff !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 18px !important;
      line-height: 23px !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .MuiTableCell-head {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: 'Red Hat Display Bold' !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
      padding: 12px 20px;
    }
    .th-title {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: 'Red Hat Display Bold' !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
    }
    .MuiTableCell-body {
      color: #000000 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      padding: 14.45px 20px !important;
      border-bottom: 1px solid #ebebeb !important;
      word-break: break-all;
    }
    .td-text {
      color: #000000 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      margin-left: 10px !important;
    }
  }
}
.custom-search {
  input {
    width: 319px !important;
    height: 45px !important;
    background-color: #f5f5f5 !important;
    border: 1px solid #e0e0e0 !important;
    padding-left: calc(1em + 32px) !important;
    margin: 7px 0;
    font-size: 14px !important;
    font-family: Red Hat Display Regular !important;
    color: #979598 !important;
  }
}

.dropdown-btn {
  background: transparent !important;
  padding: 0 !important;
  width: auto;
}

.tableview {
  thead {
    th {
      padding: 5px;
    }
  }
  tbody {
    td {
      a {
        color: #005ebf !important;
        font-family: 'Red Hat Display Bold';
        font-size: 13px;
      }
    }
  }
}

.headernav {
  ul {
    display: flex;
    justify-content: center;
    padding: 11px 0px;

    li {
      list-style: none;
      margin: 0 0px;
      width: auto;

      a {
        color: #241a2e;
        text-decoration: none;
        font-size: 15px;
        font-family: 'Red Hat Display bold';
        text-transform: uppercase;
        position: relative;
      }
    }

    .active a {
      color: #db2426 !important;

      &:after {
        content: '';
        position: absolute;
        border-bottom: 4px solid #db2426;
        width: 100%;
        bottom: -22px;
        left: 0;
      }
    }
  }
}

ul.filterlist {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 11px 0px;

  li {
    button {
      font-family: Red Hat Display SemiBold;
      font-size: 14px;
      color: #241a2e;
      text-transform: capitalize;

      span {
        margin: 0 5px;
      }
    }
  }
}

.popwidget {
  z-index: 9999;
  min-width: 160px;

  .MuiPaper-root {
    background: #292929;
    border-radius: 3px;
    p {
      color: #fff;
      font-family: 'Red Hat Display SemiBold';
      font-size: 14px;
      padding: 1px 10px;
    }
  }
}
.editmodal {
  .b-bottom {
    padding-bottom: 14px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 14px;
  }
  .c-bottom {
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
  .note {
    color: #979598 !important;
    font-family: Red Hat Display Medium !important;
    font-size: 11px !important;
    line-height: 14px !important;
    margin-bottom: 14px;
  }
  .notesTitle {
    color: #292929 !important;
    font-family: Red Hat Display Medium !important;
    font-size: 12px !important;
    line-height: 14px !important;
    margin-bottom: 5px;
  }
  .title {
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 20px !important;
    letter-spacing: 2.5px;
    line-height: 26px !important;
    padding-bottom: 14px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 14px;
  }
  .p-text {
    color: #bf0000 !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 12px !important;
    margin-bottom: 5px;
  }
  .pro-name {
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 16px !important;
    line-height: 34px !important;
  }
  .input-box {
    height: 32px;
    width: 110px;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: center;
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 16px !important;
  }
}
.model-widget {
  max-height: 90vh;
  overflow-y: auto;
  h2 {
    font-family: 'Red Hat Display Regular';
    font-size: 24px !important;
    color: #000000;
    margin-bottom: 24px !important;
  }

  span {
    font-family: 'Red Hat Display bold';
    color: #333;
    font-size: 14px;
    // text-decoration: underline;
  }

  h4 {
    font-family: 'Red Hat Display SemiBold';
    font-size: 20px;
    line-height: 41px;
    color: #292929;
    margin: 10px 0 5px;

    span {
      color: #bf0000;
      font-family: 'Red Hat Display Bold';
    }
  }

  h6 {
    font-family: 'Red Hat Display SemiBold';
    font-size: 16px;
    color: #292929;
  }

  .model-timesheet {
    border: 1px solid #e0e0e0;
    margin: 15px 0;
    min-height: 200px;
    border-radius: 10px;
    padding: 30px;
  }

  label {
    font-family: 'Red Hat Display Regular';
    font-size: 14px;
    color: #000000;
  }

  input {
    border: 1px solid #f8f8fa !important;
    margin-bottom: 5px;
    font-family: 'Red Hat Display Medium' !important;
    font-size: 14px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent;
  }

  .common-textarea {
    background: #f8f8fa;
    width: 100%;
    margin-bottom: 25px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;

    .MuiInputBase-multiline {
      padding: 0;
    }

    textarea {
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 16px !important;
      color: #979598 !important;
      height: 76px !important;
      border: 0px solid transparent !important;
      margin: 0 !important;
    }
  }

  .common-select {
    display: block;
    background: #f8f8fa;
    border: 1px solid #f8f8fa !important;
    font-family: 'Red Hat Display Medium' !important;
    font-size: 14px !important;
    border-radius: 8px;
    height: 43px;
    width: 100%;

    .MuiInputBase-input {
      padding: 10px !important;
      padding-right: 0 !important;
    }

    .MuiMenu-list li {
      font-size: 10px;
    }
  }
}

.MuiMenu-list li {
  font-family: 'Red Hat Display Medium' !important;
  font-size: 14px !important;
}

.notes {
  font-size: 11px !important;
  font-family: 'Red Hat Display medium' !important ;
  color: #979598 !important;
  line-height: 14px !important;
  border-top: 1px solid #e0e0e0;
  padding: 17px 0 !important;
  margin: 22px 0 !important;
}
.pointer {
  cursor: pointer;
}
.model-btn {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  button {
    font-family: 'Red Hat Display Bold' !important;
  }

  .MuiButton-contained {
    background: #bf0000;
    padding: 10px 20px;
    border-radius: 2px !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    margin: 0 !important;
    width: auto;
    height: 46px;

    &:hover {
      background-color: #bf0000;
    }
  }

  .MuiButton-textPrimary {
    color: #292929;
    text-decoration: underline;
    font-size: 14px;
    text-transform: capitalize;
  }
}

.sideNavactive {
  border-radius: 8px;
  background-color: rgba(191, 0, 0, 14%);
  text-decoration: none !important;
  span {
    color: #000000 !important;
  }
}
.sidebarA:active {
  text-decoration: none !important;
}

a.sidebarA {
  text-decoration: none;
}

.plusAddButton {
  background: #005ebf !important;
  border-radius: 4px !important;
  background-color: #005ebf !important;
  box-shadow: 0 2px 4px 0 rgb(0 94 191 / 40%) !important;
  padding: 0 !important;
  padding-left: 17px !important;
  color: #ffffff !important;
  font-family: Red Hat Display Bold !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  .MuiButton-endIcon {
    border-left: 1px solid #003f80 !important;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px !important;
    margin-right: 0px !important;
    margin-left: 35px !important;
  }
}
.admidSidebarDivider {
  border-color: #8c7173 !important;
}
.whiteText {
  color: #ffffff !important;
}
.commonpopover {
  p {
    background-color: #241a2e;
    filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.5));
    min-width: 217px;
    max-width: 217px !important;
  }
  .MuiFormControlLabel-label {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #ffffff;
  }
  button {
    background-color: #5d5d5d;
    width: 100%;
    font-family: 'Red Hat Display Bold';
    font-size: 14px;
    border-radius: 4px;
    text-transform: capitalize;

    &:hover {
      background-color: #5d5d5d;
    }
  }

  .MuiInputBase-root {
    width: 100% !important;
    background: #ffffff;
    height: 32px;
    margin: 10px 0;
    font-family: 'Red Hat Display SemiBold';
    font-size: 14px;
  }
}
.shadow-popover {
  .MuiPopover-paper {
    background-color: rgba(0, 0, 0, 0.84) !important;
    width: 216px;
    min-height: 48px;
    padding: 11px 18px 11px 18px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
    overflow: visible !important;
    z-index: 100;
    margin-top: 22px;
    border-radius: 0px !important;
  }
}
.shadow-popover2 {
  .MuiPopover-paper {
    background-color: rgba(0, 0, 0, 0.84) !important;
    width: 216px;
    min-height: 48px;
    padding: 11px 18px 11px 18px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
    overflow: visible !important;
    z-index: 100;
    margin-top: 22px;
    border-radius: 0px !important;
  }
}
.shadow-popover-wa {
  .MuiPopover-paper {
    background-color: rgba(0, 0, 0, 0.84) !important;
    // width: 250px;
    min-height: 48px;
    padding: 11px 12px 11px 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
    overflow: visible !important;
    z-index: 100;
    margin-top: 17px;
    border-radius: 0px !important;
  }
}
.shadow-popover-sa {
  .MuiPopover-paper {
    width: 260px;
    background-color: rgba(0, 0, 0, 0.84) !important;
    padding: 11px 12px 11px 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
    overflow: visible !important;
    z-index: 100;
    margin-top: 7px;
    border-radius: 10px !important;
  }
  .radio-text {
    color: #ffffff !important;
    line-height: 24px !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 12px !important;
    padding-top: 5px !important;
  }
  .bottomText {
    color: #cecece !important;
    line-height: 24px !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 10px !important;
    padding-top: 5px;
    border-top: 1px solid grey;
    margin-top: 10px;
  }
  .options {
    padding-left: 5px !important;
  }
  .inputDate {
    font-size: 10px !important;
    height: 25px !important;
    padding: 5px !important;
    width: 92px !important;
    background-color: #636363 !important;
    border: #636363 !important;
    color: white !important;
    margin-left: 15px;
  }
  .calender-candidate-button {
    width: 100%;
    height: 22px;
    background: #636363;
    border: #636363;
    border-radius: 4px;
    color: white !important;
    font-size: 12px !important;
  }
  .react-datepicker-wrapper {
    width: 104px;
    padding-left: 20px;
  }
}
.inner-pop-text {
  position: relative;
  p {
    color: #ffffff !important;
    font-family: 'Red Hat Display Regular' !important;
    font-size: 15px !important;
    line-height: 28px;
    cursor: pointer;
  }
  .arrow-top {
    position: absolute;
    top: -22px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.84);
    right: -14px;
    z-index: 1;
  }
  .arrow-top2 {
    position: absolute;
    top: -22px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.84);
    right: -14px;
    z-index: 1;
  }
}
.inn-text {
  .arrow-top-left {
    position: absolute;
    top: -12px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.84);
    left: 8px;
    z-index: 1;
  }
  p {
    color: #ffffff !important;
    font-family: 'Red Hat Display Regular' !important;
    font-size: 12px !important;
    line-height: 19px;
    cursor: pointer;
  }
}
.cancel-btn {
  background-color: #979598 !important;
  height: 50px;
  min-width: 84px !important;
  font-size: 17px !important;
  line-height: 22px;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-family: Red Hat Display Bold !important;
  text-transform: capitalize !important;
  text-decoration: none !important;
}
.save-btn {
  height: 50px;
  min-width: 84px !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-size: 17px !important;
  font-family: Red Hat Display Bold !important;
  background-color: #0a84ff !important;
  text-transform: capitalize !important;
}
.commonpopover1 {
  p {
    background-color: #241a2e;
    filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.5));
    min-width: 217px;
    max-width: 217px !important;
  }
  .MuiFormControlLabel-label {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #ffffff;
  }
  button {
    background-color: #5d5d5d;
    width: 100%;
    font-family: 'Red Hat Display Bold';
    font-size: 14px;
    border-radius: 4px;
    text-transform: capitalize;

    &:hover {
      background-color: #5d5d5d;
    }
  }

  .MuiInputBase-root {
    width: 100% !important;
    background: #ffffff;
    height: 32px;
    margin: 10px 0;
    font-family: 'Red Hat Display SemiBold';
    font-size: 14px;
  }
}

.default-accordin .MuiAccordion-root::before {
  border-left: 0px solid !important;
}

.default-accordin .MuiAccordion-root:first-child:before {
  border-left: 0px solid !important;
}

.default-accordin .accordin-details::before {
  display: none !important;
}

.default-accordin .Mui-expanded:before {
  border-left: 0px solid !important;
}
.edit-text {
  color: #979598 !important;
  text-decoration: underline solid #979598;
  font-family: 'Red Hat Display SemiBold !important';
  font-size: 14px !important;
  line-height: 18px !important;
  cursor: pointer;
}
.common-accordin {
  .MuiAccordion-root {
    margin: 20px 0px;
    border-radius: 10px 10px !important;
    box-shadow: 2px 0px 8px rgb(0 0 0 / 4%);
    padding: 0 15px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border-left: 2px solid #d1cbcb;
      background: transparent;
      left: -28px;
      top: 55px;
    }

    &:first-child:before {
      display: block !important;
      opacity: 1 !important;
      z-index: -1;
    }

    &:last-child:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      height: 90px;
    }
  }

  .Mui-expanded {
    &:before {
      display: block !important;
      opacity: 1 !important;
    }

    &:last-child:before {
      display: none !important;
    }
  }

  .MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0px 0;
  }

  .MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 20px !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-left: 25px;
  }

  h2 {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #292929;
    text-transform: uppercase;
    line-height: 30px;
    letter-spacing: 1.88px;
    position: relative;
    padding-left: 20px;
    margin: 10px 20px;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      background: #005ebf;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .accordin-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: #241a2e;
    padding: 10px 0;

    &::before {
      content: url(../assets/images/status-paid.svg);
      position: absolute;
      left: -57px;
    }

    p {
      font-family: 'Red Hat Display SemiBold';
      font-size: 18px;
      color: #241a2e;
      strong {
        font-family: 'Red Hat Display Bold';
        font-size: 18px;
      }
    }

    button {
      background: #bf0000;
      font-family: 'Red Hat Display Bold';
      height: 44px;
      border-radius: 2px;
      padding: 0px 20px;
      margin: 0 0px 0 25px;
      font-size: 15px;
      text-transform: capitalize;
    }
  }

  .accord-active {
    margin-right: 0 !important;
    a {
      color: #bf0000;
      font-family: 'Red Hat Display Bold';
      margin: 0 20px;
      font-size: 16px;
    }
  }
}

.payroll-box {
  .MuiAccordion-root {
    margin: 20px 0px;
    border-radius: 10px 10px !important;
    box-shadow: 2px 0px 8px rgb(0 0 0 / 4%);
    padding: 0 0px !important;
    position: relative;

    &::before {
      display: none !important;
    }
  }
}

.confirmation-widget {
  min-height: 165px;
  border-radius: 2px !important;

  h2 {
    font-size: 25px;
    font-family: 'Red Hat Display Bold';
    margin-bottom: 25px;
    text-align: center;
  }
  button {
    margin: 0 15px;
  }
  .confirm-btn {
    justify-content: center;
  }
  .MuiButton-outlined {
    border: 1px solid #e0e0e0;
    color: #292929;
    border-radius: 2px !important;
  }
}

.MuiFormHelperText-sizeMedium {
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 13px !important;
  margin-left: 0px !important;
}

.accordin-details1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #241a2e;
  padding: 10px 0;

  &::before {
    content: url(../assets/images/status-paid.svg);
    position: absolute;
    left: -55px;
    top: 25px;
  }

  p {
    font-family: 'Red Hat Display SemiBold';
    font-size: 18px;
    color: #241a2e;
    strong {
      font-family: 'Red Hat Display Bold';
      font-size: 18px;
    }
  }

  button {
    background: #bf0000;
    font-family: 'Red Hat Display Bold';
    height: 44px;
    border-radius: 2px;
    padding: 0px 20px;
    margin: 0 0px 0 25px;
    font-size: 15px;
    text-transform: capitalize;
  }
  button:hover {
    background: #bf0000;
  }
}

.accordin-details-pending {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #241a2e;
  padding: 10px 0;

  &::before {
    content: url(../assets/images/status-week.svg);
    position: absolute;
    left: -43px;
    top: 25px;
  }

  p {
    font-family: 'Red Hat Display SemiBold';
    font-size: 18px;
    color: #241a2e;
    strong {
      font-family: 'Red Hat Display Bold';
      font-size: 18px;
    }
  }

  button {
    background: #bf0000;
    font-family: 'Red Hat Display Bold';
    height: 44px;
    border-radius: 2px;
    padding: 0px 20px;
    margin: 0 0px 0 25px;
    font-size: 15px;
    text-transform: capitalize;
  }
  button:hover {
    background: #bf0000;
  }
}

.errorText {
  color: #000000 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 13px !important;
}

.errorMessage {
  color: #da5a30 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 13px !important;
}

.flex-end-content {
  justify-content: flex-end !important;
}

// .MuiTablePagination-selectLabel {
//   display: none !important;
// }

.react-tel-input .form-control {
  padding-left: 47px !important;
  width: 100% !important;
  background-color: #f3f3f3 !important;
}
.react-tel-input .flag-dropdown {
  border: 0px solid #cacaca !important;
}
.MuiInputBase-formControl .Mui-disabled {
  color: #aeaeae !important;
  -webkit-text-fill-color: #aeaeae !important;
}

.common-table {
  thead {
    th {
      color: #979598;
      padding: 0;
      font-size: 12px;
      font-family: Red Hat Display Regular;
      span {
        margin: 2px 10px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 2px solid #eee;
    }

    th {
      padding-left: 0px;
      padding-right: 0px;
    }

    th,
    td {
      color: #292929;
      font-size: 13px;
      font-style: normal;
      font-family: Red Hat Display Medium;
      padding-left: 0px;
      padding-right: 0px;

      strong {
        color: #db2426;
        font-family: Red Hat Display Bold;
      }
    }
  }
}

.scroll-wrapper {
  overflow: hidden;
}

.show-scrollbar {
  overflow-y: scroll;
  height: 30rem;
  padding-right: 1rem;
}

/* pdf view styles */
.pdf-app-views {
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.pdf-page-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin: 20px;
  height: 700px;
  width: 100%;
  // overflow-y: auto;
  // box-shadow: 0 2px 6px 0 rgba(62, 62, 62, 0.15);
  // -webkit-box-shadow: 0 2px 6px 0 rgba(62, 62, 62, 0.15);
}
.pdf-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-radius: 4%;
  z-index: 9999999;
}
.pdf-button-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

/*-------------------------Consultantstyles---------------------*/
.recruiter-body {
  min-height: calc(100vh - 108px);
  background-color: #f5f5f5;
  overflow-y: auto;
  padding: 20px;
}
.job-view-body {
  height: calc(100vh - 108px);
  background-color: #f5f5f5;
  overflow-y: auto;
  padding: 20px 0;
}
.exp-table {
  table {
    width: 100%;
    border-collapse: unset !important;
    border-spacing: 0;
  }
  thead {
    th {
      font-size: 12px !important;
      padding: 10px;
      text-align: left !important;
    }
    .w-50 {
      width: 50%;
    }
    .w-25 {
      width: 27%;
      text-align: right;
    }
    .w-23 {
      width: 23%;
      text-align: right;
    }
  }
  tbody {
    tr:nth-child(odd) {
      background-color: #e9e9e9;
    }
    td {
      padding: 11px 12px;
    }
    tr {
      td:nth-child(2) {
        text-align: right;
      }
      td:last-child {
        text-align: right;
      }
    }
  }
}
.rec-add-btn {
  height: 47px;
  width: 139px;
  background-color: #0262b9 !important;
  box-shadow: 0 1px 2px 0 rgba(44, 24, 81, 0.35) !important;
  color: #ffffff !important;
  font-family: Red Hat Display Medium !important;
  font-size: 15px !important;
  letter-spacing: -0.25px;
  line-height: 20px;
  border-radius: unset !important;
  text-transform: capitalize !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.job-delete-modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}
.w-95 {
  width: 95%;
}
.w-42 {
  width: 42%;
}
.w-5 {
  width: 5%;
}
.MuiPagination-ul {
  justify-content: center;
}
.filter-drawer {
  .MuiBackdrop-root {
    background-color: unset !important;
  }
  .MuiDrawer-paper {
    top: 60px;
    width: 444px;
    background-color: #242225 !important;
    box-shadow: unset !important;
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .MuiAccordion-root {
    background-color: unset !important;
    box-shadow: unset !important;
  }
  .MuiAccordionSummary-root {
    min-height: 55px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .px-12 {
    padding: 0 18px !important;
  }
  .MuiAccordionDetails-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .MuiAccordionSummary-expandIconWrapper {
    padding-top: 12px;
    svg {
      fill: #979797;
    }
  }
  .MuiAccordionSummary-content {
    margin: 12px 0 0px 0 !important;
  }
  .row-pad {
    padding: 20px 18px 0px 18px;
  }
  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-50 {
    margin-top: 50px !important;
  }
  .dra-f-title {
    color: #ffffff !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 16px !important;
  }
  .td-check {
    color: #979797 !important;
    &.Mui-checked {
      color: #80bd03 !important;
    }
  }
  .job-radio {
    color: #979797 !important;
    &.Mui-checked {
      color: red !important;
    }
  }
  .options {
    margin: 0 26px 0 4px !important;
    font-size: 17px !important;
    color: #ffffff !important;
  }
  .dra-title {
    color: #ffffff !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 18px !important;
    line-height: 23px !important;
    padding: 21px 0 16px 20px;
    border-bottom: 1px solid #3f3d3f;
  }
  .MuiSlider-root {
    color: rgba(120, 120, 128, 0.2) !important;
  }
  .MuiSlider-markLabel {
    top: 35px !important;
    color: #ffffff !important;
    font-size: 14px !important;
  }
  .MuiSlider-track {
    background-color: #007aff !important;
  }
  .MuiSlider-thumb {
    width: 28px !important;
    height: 28px !important;
    background-color: #ffffff;
    box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.12),
      0 6px 13px 0 rgba(0, 0, 0, 0.12);
  }
  .m-blue-btn {
    height: 50px !important;
    min-width: 84px !important;
    border-radius: 8px !important;
    font-size: 17px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
    line-height: 22px;
    font-family: Red Hat Display Bold !important;
    background-color: #0a84f5 !important;
    padding: 0 15px;
  }
  .search-item {
    min-height: 26px;
    min-width: 82px;
    border-radius: 13px;
    background-color: #0262b9;
    padding: 0 10px;
    font-size: 14px !important;
    color: #ffffff !important;
    margin-right: 4px !important;
    margin-top: 8px !important;
    display: flex;
    align-items: center;
    svg {
      font-size: 17px !important;
      fill: #979797 !important;
      margin-left: 4px;
      cursor: pointer;
    }
  }
  .border {
    height: 1px;
    width: 100%;
    opacity: 0.15;
    background-color: #d8d8d8;
  }
}

.resume-validation-filter-title {
  font-size: 25px !important;
  line-height: 23px !important;
  padding: 16px !important;
  font-family: red Hat Display SemiBold !important;
  color: #000000;
  display: flex !important;
  justify-content: space-between !important;
}

.resume-validation-filter-drawer {
  .MuiDrawer-paper {
    top: 60px;
    // width: 444px;
    width: 365px;
    background-color: #ffffff !important;
    box-shadow: unset !important;
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .MuiAccordion-root {
    background-color: unset !important;
    box-shadow: unset !important;
  }
  .MuiAccordionSummary-root {
    min-height: 55px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .px-12 {
    padding: 0 18px !important;
  }

  .row-pad {
    padding: 20px 18px 0px 18px;
  }
  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-50 {
    margin-top: 50px !important;
  }
  .dra-f-title {
    color: #ffffff !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 16px !important;
  }
  .td-check {
    color: #979797 !important;
    &.Mui-checked {
      color: #80bd03 !important;
    }
  }
  .job-radio {
    color: #979797 !important;
    &.Mui-checked {
      color: red !important;
    }
  }
  .options {
    margin: 0 26px 0 4px !important;
    font-size: 17px !important;
    color: #ffffff !important;
  }
  .dra-title {
    color: #ffffff !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 18px !important;
    line-height: 23px !important;
    padding: 21px 0 16px 20px;
    border-bottom: 1px solid #3f3d3f;
  }
  .MuiSlider-root {
    color: rgba(120, 120, 128, 0.2) !important;
  }
  .MuiSlider-markLabel {
    top: 35px !important;
    color: #ffffff !important;
    font-size: 14px !important;
  }
  .MuiSlider-track {
    background-color: #007aff !important;
  }
  .MuiSlider-thumb {
    width: 28px !important;
    height: 28px !important;
    background-color: #ffffff;
    box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.12),
      0 6px 13px 0 rgba(0, 0, 0, 0.12);
  }
  .m-blue-btn {
    height: 50px !important;
    min-width: 84px !important;
    border-radius: 8px !important;
    font-size: 17px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
    line-height: 22px;
    font-family: Red Hat Display Bold !important;
    background-color: #0a84f5 !important;
    padding: 0 15px;
  }
  .search-item {
    min-height: 26px;
    min-width: 82px;
    border-radius: 13px;
    background-color: #0262b9;
    padding: 0 10px;
    font-size: 14px !important;
    color: #ffffff !important;
    margin-right: 4px !important;
    margin-top: 8px !important;
    display: flex;
    align-items: center;
    svg {
      font-size: 17px !important;
      fill: #979797 !important;
      margin-left: 4px;
      cursor: pointer;
    }
  }
  .border {
    height: 1px;
    width: 100%;
    opacity: 0.15;
    background-color: #d8d8d8;
  }
}

.resume-val-clear-all {
  background-color: #fcec11 !important;
  color: #000000 !important;
  cursor: pointer;
  font-family: Red Hat Display SemiBold !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  height: 16px !important;
  // left: 1290px !important;
  line-height: 15.88px !important;
  text-align: left;
  top: 91px !important;
  width: 48px !important;
}

.custom-resume-searchbar {
  input {
    width: 450px !important;
    height: 45px !important;
    background-color: #f5f5f5 !important;
    border: 1px solid #e0e0e0 !important;
    padding-left: calc(1em + 32px) !important;
    margin: 7px 0;
    font-size: 14px !important;
    font-family: Red Hat Display Regular !important;
    color: #979598 !important;
  }
}

.resume-filter-search-w {
  input {
    // width: 420px !important;
    // width: 380px !important;
    width: 295px !important;
    height: 45px !important;
    background-color: #ffffff !important;
    border: none !important;
    // padding-left: calc(1em + 32px) !important;
    // padding-right: 0px !important;
    margin: 7px 0;
    font-size: 14px !important;
    font-family: Red Hat Display Regular !important;
    color: #979598 !important;
  }
}

.no-record-found {
  color: #000000 !important;
  font-family: Red Hat Display SemiBold !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center;
}

.res-val-filter-box {
  display: flex !important;
  flex-direction: column !important;
  padding: 16px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.padd-16 {
  padding: 16px !important;
}
.no-btn {
  background-color: #aaaaaa !important;
  border-radius: 8px;
  height: 50px !important;
  width: 124px !important;
  font-size: 17px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  line-height: 22px;
  font-family: Red Hat Display Bold !important;
  text-decoration: unset !important;
}
.job-delete-btn {
  background-color: #bf0000 !important;
  border-radius: 8px;
  height: 50px !important;
  width: 210px !important;
  font-size: 17px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  line-height: 22px;
  font-family: Red Hat Display Bold !important;
  text-decoration: unset !important;
}
.MuiSlider-thumb::after {
  width: 28px !important;
  height: 28px !important;
}
.jobscroll-modal {
  .MuiDialog-paper {
    max-width: unset !important;
    min-height: 768px;
    width: 780px !important;
    background-color: #ffffff;
    border-radius: unset !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08) !important;
  }
  .n-label {
    font-size: 15px !important;
    line-height: 20px !important;
    margin-bottom: 4px !important;
  }
  .upload-btn {
    height: 40px !important;
    width: 113px !important;
    border-radius: 8px !important;
    font-size: 17px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
    line-height: 22px;
    font-family: Red Hat Display Bold !important;
    background-color: #0a84f5 !important;
  }
  .m-blue-btn {
    height: 50px !important;
    min-width: 84px !important;
    border-radius: 8px !important;
    font-size: 17px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
    line-height: 22px;
    font-family: Red Hat Display Bold !important;
    background-color: #0a84f5 !important;
    padding: 0 15px;
  }
  .mr-8 {
    margin-right: 8px !important;
  }
  .table-title {
    font-family: Red Hat Display Bold !important;
    font-size: 12px !important;
    margin: 14px 0 9px 0 !important;
  }
  .MuiSlider-root {
    color: rgba(120, 120, 128, 0.2) !important;
  }
  .MuiSlider-track {
    background-color: #007aff !important;
  }
  .MuiSlider-thumb {
    width: 28px !important;
    height: 28px !important;
    background-color: #ffffff;
    box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.12),
      0 6px 13px 0 rgba(0, 0, 0, 0.12);
  }
  .MuiDialogContent-root {
    padding: 0 54px;
    min-height: 100px;
    overflow: unset !important;
    .td-check {
      color: #979797 !important;
      &.Mui-checked {
        color: #80bd03 !important;
      }
    }
    .options {
      margin: 0 26px 0 4px !important;
      font-size: 17px !important;
    }
  }
  .MuiDialogActions-root {
    padding: 0 31px 33px 54px;
  }
  .add-m-title {
    padding: 51px 0 26px 54px;
    color: #000000;
    font-size: 24px !important;
    letter-spacing: 0;
    line-height: 22px;
    font-family: Red Hat Display SemiBold !important;
  }
}
.recruter-h1 {
  color: #000000;
  font-family: Red Hat Display SemiBold !important;
  font-size: 25px !important;
  line-height: 33px !important;
}
.recruiter-jobpost-table {
  ::-webkit-scrollbar {
    width: 1px;
  }
  &.MuiTableContainer-root {
    box-shadow: unset !important;
    background-color: unset !important;
    border-radius: 20px !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    // th {
    //   .MuiSelect-select {
    //     // width: calc(100vw - 88vw);
    //   }
    // }
    tbody {
      .MuiTableRow-root {
        box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.05);
        border-radius: 20px;
      }
      tr {
        td:first-child {
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        }
        td:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .w-10 {
      width: 10%;
    }
    .w-25 {
      width: 25%;
    }
    .w-15 {
      width: 15%;
    }
    .w-5 {
      width: 5%;
    }
    .w-19 {
      width: 19%;
    }
    .w-18 {
      width: 18%;
    }
    .w-35 {
      width: 35%;
    }
    .w-13 {
      width: 13%;
    }
    .w-20 {
      width: 20%;
    }
    .w-24 {
      width: 24%;
    }
    .w-30 {
      width: 30%;
    }

    .logo-avator {
      height: 50px;
      width: 50px;
      color: #ffffff !important;
      font-family: Red Hat Display Bold !important;
      font-size: 18px !important;
      line-height: 23px !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 12px !important;
    }
    .match-badge {
      height: 24px;
      width: 75px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(3, 35, 189, 0.25);
      padding: 0 8px;
      color: #0323bd !important;
      font-family: Red Hat Display Bold !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .post-badge {
      height: 24px;
      width: 75px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgb(247 224 225);
      padding: 0 8px;
      color: #ef6f75 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .tick-badge {
      img {
        margin-right: 2px !important;
      }
      height: 24px;
      width: 83px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(128, 189, 1, 0.25);
      padding: 0 8px;
      color: #80bd03 !important;
      font-family: Red Hat Display Bold !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .MuiTableCell-head {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: Red Hat Display Bold !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
      padding: 12px 8px;
      border-bottom: unset !important;
    }
    .th-title {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: Red Hat Display Bold !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
    }
    .MuiTableCell-body {
      color: #000000 !important;
      font-family: Red Hat Display SemiBold !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      padding: 15px 8px !important;
      word-break: break-all;
      background-color: #fff;
      border-bottom: unset !important;
      border-top: unset !important;
    }
    .td-text {
      color: #000000 !important;
      font-family: Red Hat Display SemiBold !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      margin-left: 10px !important;
    }
  }
}

.resume-validation-table {
  ::-webkit-scrollbar {
    width: 1px;
  }
  &.MuiTableContainer-root {
    box-shadow: unset !important;
    background-color: unset !important;
    border-radius: 20px !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    th {
      .MuiSelect-select {
        width: calc(100vw - 88vw);
      }
    }
    tbody {
      .MuiTableRow-root {
        box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.05);
        border-radius: 20px;
      }
      tr {
        td:first-child {
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        }
        td:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
    .w-10 {
      width: 10%;
    }
    .w-25 {
      width: 25%;
    }
    .w-15 {
      width: 15%;
    }
    .w-5 {
      width: 5%;
    }
    .w-19 {
      width: 19%;
    }
    .w-18 {
      width: 18%;
    }
    .w-35 {
      width: 35%;
    }
    .w-13 {
      width: 13%;
    }
    .w-20 {
      width: 20%;
    }
    .w-24 {
      width: 24%;
    }
    .w-30 {
      width: 30%;
    }

    .logo-avator {
      height: 50px;
      width: 50px;
      color: #ffffff !important;
      font-family: Red Hat Display Bold !important;
      font-size: 18px !important;
      line-height: 23px !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 12px !important;
    }
    .match-badge {
      height: 24px;
      width: 75px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(3, 35, 189, 0.25);
      padding: 0 8px;
      color: #0323bd !important;
      font-family: Red Hat Display Bold !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: none !important;
    }
    .post-badge {
      height: 24px;
      width: 75px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgb(247 224 225);
      padding: 0 8px;
      color: #ef6f75 !important;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .tick-badge {
      img {
        margin-right: 2px !important;
      }
      height: 24px;
      width: 83px;
      // opacity: 0.25;
      border-radius: 12px;
      background-color: rgba(128, 189, 1, 0.25);
      padding: 0 8px;
      color: #80bd03 !important;
      font-family: Red Hat Display Bold !important;
      font-size: 10px !important;
      letter-spacing: 0.83px;
      line-height: 13px;
      text-transform: uppercase !important;
    }
    .MuiTableCell-head {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: Red Hat Display Bold !important;
      line-height: 16px !important;
      // text-transform: uppercase !important;
      text-transform: unset !important;
      background-color: #f5f5f5 !important;
      padding: 12px 8px;
      border-bottom: unset !important;
    }
    .th-title {
      color: #bdbdbd !important;
      font-size: 12px !important;
      font-family: Red Hat Display Bold !important;
      line-height: 16px !important;
      text-transform: uppercase !important;
      background-color: #f5f5f5 !important;
    }
    .MuiTableCell-body {
      color: #000000 !important;
      font-family: Red Hat Display SemiBold !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      padding: 15px 8px !important;
      word-break: break-all;
      background-color: #fff;
      border-bottom: unset !important;
      border-top: unset !important;
    }
    .td-text {
      color: #000000 !important;
      font-family: Red Hat Display SemiBold !important;
      font-size: 15px !important;
      letter-spacing: 0;
      line-height: 20px !important;
      margin-left: 10px !important;
    }
  }
}
.side-body {
  background-color: #ffffff;
  //height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  //border-top: 1px solid #dedede;
  .side-head {
    padding: 16px 17px 16px 20px;
    border-bottom: 1px solid #d8d8d8;
    .titles {
      font-family: Red Hat Display SemiBold !important;
      font-size: 18px !important;
    }
    .mr-10 {
      margin-right: 10px !important;
    }
    .header-filter-box {
      height: 49px;
      min-width: 49px !important;
      border: 1px solid #d7d7d7 !important;
      border-radius: 4px !important;
      margin-right: 9px !important;
    }
  }
}
.contain-box {
  border-bottom: 1px solid #d4d4d4;
  padding: 25px 0;
  .contain-title {
    font-family: Red Hat Display SemiBold !important;
    font-size: 15px !important;
  }
  .contain-info {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .wrapper-info {
    p {
      margin: 0;
    }
  }
  .degree {
    font-family: Red Hat Display !important;
  }
}
.contain-box-file {
  margin-top: 25px !important;
  border-top: 1px solid #d4d4d4;
  padding: 25px 0;
  padding-bottom: 0px !important;
  .contain-title {
    font-family: Red Hat Display SemiBold !important;
    font-size: 15px !important;
  }
  .contain-info {
    font-size: 15px !important;
    color: #1854b6 !important;
    cursor: pointer;
    line-height: 1.6;
  }
}
.count-job-box {
  min-height: 96px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  flex-direction: column;
  &.range {
    border-bottom: 4px solid #40af7b;
    .c-text {
      font-size: 15px !important;
      line-height: 20px !important;
      color: #40af7b !important;
      margin-top: 5px !important;
      font-family: Red Hat Display SemiBold !important;
    }
  }
  &.date {
    border-bottom: 4px solid #2f8bbf;
    .c-text {
      font-size: 15px !important;
      line-height: 20px !important;
      color: #2f8bbf !important;
      margin-top: 5px !important;
      font-family: Red Hat Display SemiBold !important;
    }
  }
  &.hired {
    border-bottom: 4px solid #6b27a4;
    .c-text {
      font-size: 15px !important;
      line-height: 20px !important;
      color: #6b27a4 !important;
      margin-top: 5px !important;
      font-family: Red Hat Display SemiBold !important;
    }
  }
}
.l-tit {
  font-size: 15px !important;
  font-family: Red Hat Display SemiBold !important;
}
.job-h-titles {
  padding-bottom: 15px;
  border-bottom: 1px solid #d4d4d4;
  .in-h {
    font-family: Red Hat Display Bold !important;
    font-size: 18px !important;
    line-height: 23px;
    text-transform: capitalize !important;
  }
  .sub-info {
    margin-top: 5px !important;
    .comp-name {
      font-family: Red Hat Display SemiBold !important;
      font-size: 15px !important;
      margin-right: 4px !important;
      text-transform: capitalize !important;
    }
    .lr {
      color: #727272 !important;
      font-family: Red Hat Display SemiBold !important;
      font-size: 15px !important;
      margin-right: 4px !important;
      text-transform: capitalize;
    }
    .days {
      color: #727272 !important;
      font-size: 12px !important;
    }
  }
  .match-badge {
    height: 24px;
    width: 75px;
    // opacity: 0.25;
    border-radius: 12px;
    background-color: rgba(3, 35, 189, 0.25);
    padding: 0 8px;
    color: #0323bd !important;
    font-family: 'Red Hat Display Bold' !important;
    font-size: 10px !important;
    letter-spacing: 0.83px;
    line-height: 13px;
    text-transform: uppercase !important;
  }
  .post-badge {
    height: 24px;
    width: 75px;
    // opacity: 0.25;
    border-radius: 12px;
    background-color: rgb(247 224 225);
    padding: 0 8px;
    color: #ef6f75 !important;
    font-family: 'Red Hat Display Bold' !important;
    font-size: 10px !important;
    letter-spacing: 0.83px;
    line-height: 13px;
    text-transform: uppercase !important;
  }
  .tick-badge {
    img {
      margin-right: 2px !important;
    }
    height: 24px;
    width: 83px;
    // opacity: 0.25;
    border-radius: 12px;
    background-color: rgba(128, 189, 1, 0.25);
    padding: 0 8px;
    color: #80bd03 !important;
    font-family: 'Red Hat Display Bold' !important;
    font-size: 10px !important;
    letter-spacing: 0.83px;
    line-height: 13px;
    text-transform: uppercase !important;
  }
}
.jobview-header {
  min-height: 48px;
  background-color: #292929;
  .job-dis {
    font-size: 15px !important;
    color: #ffffff !important;
    font-family: Red Hat Display SemiBold !important;
    &-activ {
      font-family: Red Hat Display SemiBold !important;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #7fbc01 !important;
      text-decoration: underline;
      text-decoration-color: #7fbc01;
      text-underline-offset: 5px;
      text-decoration-thickness: 2px;
    }
  }
  .back {
    color: #d8d8d8 !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 14px !important;
    margin-left: 4.5px;
    cursor: pointer;
  }
  .edit-btn {
    height: 40px !important;
    width: 79px !important;
    background-color: #727272 !important;
    box-shadow: 0 1px 2px 0 rgba(44, 24, 81, 0.35) !important;
    color: #ffffff !important;
    font-family: Red Hat Display Medium !important;
    font-size: 15px !important;
    letter-spacing: -0.25px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    margin-right: 4px !important;
  }
  .close-btn {
    height: 40px !important;
    width: 149px !important;
    background-color: #0262b9 !important;
    box-shadow: 0 1px 2px 0 rgba(44, 24, 81, 0.35) !important;
    color: #ffffff !important;
    font-family: Red Hat Display Medium !important;
    font-size: 15px !important;
    letter-spacing: -0.25px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
  }
}
.match-body {
  height: calc(100vh - 222px);
  overflow-y: auto;
  overflow-x: hidden;
}
.match-item {
  min-height: 145px;
  padding: 18px;
  border-bottom: 1px solid #d8d8d8;
  .match-title {
    font-family: Red Hat Display SemiBold !important;
    font-size: 16px !important;
    line-height: 21px !important;
  }
  .wa-badge {
    height: 24px;
    width: 104px;
    border-radius: 12px;
    background-color: #d4e9c0;
    color: #80bd03 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 10px !important;
    letter-spacing: 0.83px !important;
    margin-right: 8px !important;
    text-transform: uppercase;
  }
  .wa-badge-assignment {
    height: 24px;
    width: 135px;
    border-radius: 12px;
    background-color: rgba(3, 35, 189, 0.25);
    color: #0323bd !important;
    font-family: Red Hat Display Bold !important;
    font-size: 10px !important;
    letter-spacing: 0.83px !important;
    margin-right: 8px !important;
    text-transform: uppercase;
  }
  .wa-badge-prospective {
    height: 24px;
    width: 125px;
    border-radius: 12px;
    background-color: #f3b6b9;
    color: #b61f28 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 10px !important;
    letter-spacing: 0.83px !important;
    margin-right: 8px !important;
    text-transform: uppercase;
  }
  .match-addres {
    color: #727272 !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-bottom: 6px;
  }
  .a-link {
    color: #0262b9 !important;
    text-decoration: underline solid #0262b9 !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 12px !important;
    letter-spacing: 0;
    line-height: 16px !important;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .nm-txt {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .red-fill {
    fill: #bf0000;
  }
  .lat-box {
    border-radius: 3px;
    background-color: #ededed;
    padding: 9px 12px;
    margin-top: 8px;
    .lat-title {
      color: #727272 !important;
      font-family: Red Hat Display Bold !important;
      font-size: 10px !important;
      letter-spacing: 0.83px !important;
      line-height: 13px !important;
      margin-bottom: 3px;
      text-transform: uppercase;
    }
    .lat-dis {
      color: #727272 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      height: 51px;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;
      word-break: break-all;
    }
    .view-more {
      color: #727272 !important;
      text-decoration: underline solid #727272 !important;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
}
.page-count {
  padding: 23px 0px 23px 20px;
  border-top: 1px solid #d8d8d8;
  .MuiPaginationItem-root {
    font-size: 15px !important;
    font-family: Red Hat Display SemiBold !important;
    &.Mui-selected {
      background-color: #d00000 !important;
      color: #fff !important;
      font-size: 15px !important;
      font-family: Red Hat Display SemiBold !important;
    }
  }
}
.filter-header {
  min-height: 48px;
  background-color: #292929;
  display: flex;
  align-items: center;
  padding-left: 171px;
  p {
    font-family: Red Hat Display Medium !important;
    color: #ffffff !important;
    font-size: 14px !important;
    margin-right: 24px !important;
    min-width: 37px;
    padding: 0 5px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      content: '';
      border-bottom: 5px solid #7fbc01;
    }
  }
}
.Consultant-header {
  background: #fff;
  height: 60px;
  margin: 0px;
  border-bottom: 1px solid #dedede;
  img {
    width: 117px;
  }

  ul {
    display: flex;
  }

  li {
    list-style: none;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Red Hat Display bold;
    position: relative;
    margin: 0 10px;
    line-height: 21px;

    a {
      color: #656565 !important;
      text-decoration: none;
      font-family: Red Hat Display Medium !important;
      text-transform: capitalize !important;
    }
  }

  .active a {
    color: #000000 !important;

    &:after {
      content: '';
      position: absolute;
      border-bottom: 6px solid #bf0000;
      width: 100%;
      bottom: -19px;
      left: 0;
    }
  }
  .Header-notification {
    background: #f8f8fa;
    padding: 42px 35px !important;

    img {
      width: 25px;
    }
  }

  .request-payment {
    color: #292929;
    font-family: Red Hat Display Medium !important;
    text-align: center;
    h6 {
      font-size: 13px;
      font-family: 'Red Hat Display Medium';
    }
    h5 {
      font-size: 25px;
      font-family: 'Red Hat Display Medium';
    }
  }

  button {
    border: 1px solid #bf0000;
    font-size: 12px;
    font-family: 'Red Hat Display Bold';
    color: #bf0000;
    text-transform: capitalize;

    &:hover {
      border: 1px solid #bf0000;
      background: transparent;
    }
  }

  .notify-bg {
    display: flex;
    align-items: center;
    background: #f8f8fa;

    P {
      padding: 12px 35px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
  }
}
.Resumes-validation-container {
  header {
    .firstbox {
      display: flex;
      flex-flow: row nowrap;
      column-gap: 8px;
      align-items: center;
      .txt {
        text-transform: capitalize;
        // font-family: Red Hat Display Bold !important;
        // font-style: normal;
        // font-size: 14px;
        // line-height: 19px;
        /* identical to box height */

        color: #000000;

        font-family: Red Hat Display Bold !important;
        font-size: 18px !important;
        line-height: 23px;
        text-transform: capitalize !important;
      }
      .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        gap: 1px;
        border-radius: 20px;
        background-color: #c1c3e7;
        padding: 4px 11px;
        text-align: end;
        font-family: Red Hat Display Bold !important;
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 11px;
        /* identical to box height */

        color: #0725bd;

        // color: #ef6f75 !important;
        font-family: 'Red Hat Display Bold' !important;
        font-size: 10px !important;
        letter-spacing: 0.83px;
        line-height: 13px;
        text-transform: uppercase !important;
      }
    }
    .secondbox {
      margin-top: 1px;
      margin-top: 5px;
      display: flex;
      flex-flow: row nowrap;
      column-gap: 8px;
      align-items: center;
      .txt {
        .texted {
          text-transform: capitalize;
          // font-family: Red Hat Display Medium !important;
          // font-style: normal;
          // font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */

          color: #000000;

          font-family: Red Hat Display SemiBold !important;
          font-size: 15px !important;
          margin-right: 4px !important;
          text-transform: capitalize !important;
        }
        .location {
          color: #727272 !important;
        }
        .month {
          // text-transform: capitalize;
          margin-left: 6px;
          // font-family: Red Hat Display Regular !important;
          font-style: normal;
          font-weight: 400;
          // font-size: 8px;
          line-height: 11px;
          /* identical to box height */

          // color: #000000;

          color: #727272;
          font-size: 12px !important;
          font-family: Red Hat Display Regular !important;
        }
      }
    }
  }
}
.container-block {
  width: 98%;
  margin: 30px auto !important;
}

.Adjustment {
  width: 94%;
}

.status-details {
  margin-bottom: 10px !important;
}

.expand-new {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.sigCanvas {
  margin: 0 auto;
  display: table;
  background: #eeeef1;
}

.notices-details {
  display: flex;
  P {
    font-family: 'Red Hat Display Medium' !important;
    font-size: 13px !important;
    color: #979598 !important;
    margin: 10px 0px !important;
    line-height: 17px;
    a {
      color: #979598;
    }
  }
}

.timesheet-details {
  border: 1px solid #e0e0e0;
  min-height: 200px;
  border-radius: 10px;
  padding: 20px;
  p {
    font-family: 'Red Hat Display bold';
    font-size: 16px;
    color: #241a2e;

    em {
      font-family: 'Red Hat Display SemiBold';
      font-style: normal;
    }

    thead {
      th {
        font-family: 'Red Hat Display SemiBold';
        font-size: 13px;
        color: #292929;
        padding: 0 5px 10px;
      }

      .hours-timesheet {
        font-family: 'Red Hat Display SemiBold' !important;
        font-size: 16px !important;

        span {
          font-family: 'Red Hat Display Bold';
        }
      }
    }

    tbody {
      tr {
        border-bottom: 4px solid #fff;
      }
      th,
      td {
        font-family: 'Red Hat Display Bold';
        color: #292929;
        font-size: 16px;
        padding: 5px 15px;
        position: relative;

        a {
          color: #005ebf;
          padding-right: 8px;
          font-family: 'Red Hat Display Bold';
          text-decoration: underline;
          line-height: 22px;
          display: inline-block;
        }
        input {
          width: 100px;
          margin: 0 auto;
          text-align: center;
          height: 40px !important;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e0e0e0 !important;
          font-family: Red Hat Display Bold !important;
          font-size: 16px !important;
          color: #241a2e !important;
        }
      }

      .active-blue {
        background: rgba(0, 94, 191, 0.06);
        position: relative;
        border-left: 4px solid #005ebf;
        border-bottom: 4px solid #fff;
      }

      .active-gray {
        background: #f3f3f3;
        position: relative;
        border-left: 4px solid #d1d1d1;
        border-bottom: 4px solid #fff;

        th {
          color: #979598;
        }

        img {
          position: relative;
          top: 3px;
          left: -5px;
        }
      }
      .active-yellow {
        background: rgba(191, 107, 0, 0.1);
        border-left: 4px solid #e86b07;
        border-bottom: 4px solid #fff;

        td {
          a {
            color: #9a7878 !important;
          }

          img {
            position: relative;
            top: 3px;
            left: -5px;
          }
        }
      }
    }
  }
  .notices {
    a {
      font-family: 'Red Hat Display Bold';
      font-size: 12px;
      color: #979598;
      float: left;
      margin: 10px 20px 0px 0px;
    }
  }
}

.timesheet-table {
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0 10px;
  p {
    font-family: Red Hat Display SemiBold;
    font-size: 14px;
    color: #292929;
    strong {
      font-family: 'Red Hat Display Bold';
    }
  }

  table {
    thead {
      th {
        font-family: 'Red Hat Display Bold';
        font-size: 14px;
        color: #292929;
        padding: 5px 0;
      }
    }

    tbody {
      th,
      td {
        font-family: 'Red Hat Display SemiBold';
        font-size: 14px;
        color: #292929;
        padding: 5px 0;
      }
      a {
        color: #005ebf;
        font-size: 14px;
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}

.model-timesheet {
  p {
    font-family: 'Red Hat Display SemiBold';
    display: inline;
    font-size: 18px;
    color: #292929;
    span {
      font-size: 13px;
      font-family: 'Red Hat Display SemiBold';
    }
    strong {
      font-family: Red Hat Display Bold;
    }
  }
}

.paymentbox {
  height: 385px;
  overflow-y: scroll;

  h6 {
    font-size: 12px;
    font-family: 'Red Hat Display SemiBold';
    color: #979598;
    margin: 10px;
  }
}

.otherpay {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;

  .billing-details {
    P {
      font-family: 'Red Hat Display SemiBold';
      font-size: 15px;

      a {
        font-family: 'Red Hat Display Bold';
        color: #005ebf;
        font-size: 12px;
      }
    }
    h4 {
      font-family: 'Red Hat Display Bold';
      font-size: 16px;
      color: #292929;
      line-height: 22px;
    }
  }

  .bill-payments {
    display: flex;
    p {
      flex-basis: 33.3%;
      font-family: Red Hat Display SemiBold;
      font-size: 14px;
      padding: 4px 0;

      strong {
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}

.paysheet-table {
  thead {
    tr {
      border-bottom: 2px solid #eee;
    }
    th {
      color: #979598;
      padding: 0;
      font-size: 12px;
      font-family: Red Hat Display Regular;
      padding: 5px 8px;
    }
  }
  tbody {
    tr {
      &:nth-child(2) {
        background-color: rgba(0, 94, 191, 6%);
      }
    }
    td,
    th {
      color: #292929;
      font-size: 16px;
      font-style: normal;
      font-family: 'Red Hat Display SemiBold';
      padding: 12px 15px;

      a {
        color: #005ebf;
        font-size: 12px;
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}

.paysheet-title {
  margin-bottom: 25px;
  p {
    font-family: 'Red Hat Display SemiBold';
    display: inline-block;
    margin-right: 35px;
    font-size: 18px;
    strong {
      font-family: 'Red Hat Display Bold';
    }
    a {
      color: #292929;
      font-family: 'Red Hat Display Bold';
    }
  }
}

.paysheet-total {
  h4 {
    font-family: 'Red Hat Display Bold';
    font-size: 20px;
    margin: 25px 0;
    padding: 0px 15px;
  }
}

.paysheet-list {
  background: #f8f8fa !important;
  min-height: 54px;
  width: 100%;
  padding: 0 20px !important;
  P {
    font-family: 'Red Hat Display SemiBold';
    font-size: 16px;
    color: #979598;
    margin-right: 25px;
    line-height: 37px;
  }
}

.otherpay {
  margin: 10px 0 !important;
  h3 {
    font-family: 'Red Hat Display Bold';
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
  }
}

.request-payment-alert {
  padding: 30px !important;
  border-radius: 10px;
  box-shadow: 1px 5px 5px rgb(0 0 0 / 4%);
  background: #ffffff;
  margin: 25px 0 !important;

  .total-payment {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 12px;

    p {
      font-family: 'Red Hat Display SemiBold';
      font-size: 20px;
      color: #292929;
      strong {
        color: #bf0000;
        font-family: 'Red Hat Display Bold';
      }
    }
  }

  .total-payment-option {
    display: flex;
    justify-content: space-between;
    span {
      font-family: Red Hat Display SemiBold;
      font-size: 16px;
      color: #241a2e;
    }
    input {
      width: 110px;
      height: 40px !important;
      border: 1px solid #d5d5d5 !important;
      border-radius: 4px !important;
      text-align: center;
      font-family: Red Hat Display Bold !important;
      font-size: 16px !important;
    }
  }

  .paymentrequest-table {
    margin-top: 15px;
    table {
      thead {
        th {
          color: #bf0000;
          font-family: 'Red Hat Display SemiBold';
          font-size: 12px;
          padding: 0px 0px;
        }
      }

      tbody {
        th,
        td {
          font-family: 'Red Hat Display Bold';
          font-size: 16px;
          color: #292929;
          padding: 0px 0px;

          span {
            font-size: 16px;
            position: relative;
            left: 23px;
            font-family: 'Red Hat Display Bold';
          }
        }
        input {
          width: 110px;
          height: 32px !important;
          border: 1px solid #d5d5d5 !important;
          border-radius: 4px !important;
          text-align: center;
          font-family: Red Hat Display Bold !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

.tableFooterlabel {
  font-family: Red Hat Display SemiBold !important;
  font-size: 14px !important;
  color: #292929 !important;
  margin: 0px !important;
}

.tableFooterlabel1 {
  font-family: Red Hat Display Bold !important;
  font-size: 14px !important;
  color: #292929 !important;
  margin: 0px !important;
}

._loading_overlay_overlay {
  position: fixed !important;
  z-index: 10000 !important;
}
._loading_overlay_wrapper {
  width: 100% !important;
}
._version_bold_text {
  font-family: Red Hat Display Bold !important;
  font-size: 15px;
  color: #292929;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 1.88px;
  position: relative;
  font-weight: bold !important;
}
._version_semi-bold_text {
  font-family: Red Hat Display SemiBold !important;
  color: #292929;
  line-height: 30px;
  letter-spacing: 1.88px;
  position: relative;
  font-weight: bold !important;
}
._version_semi-bold_head_text {
  font-family: Red Hat Display SemiBold !important;
  color: #292929 !important;
  line-height: 30px !important;
  letter-spacing: 1.2px !important;
  position: relative !important;
  font-weight: bold !important;
}
.cke_contents {
  height: 300px !important;
}
.orangeButton {
  background: #bf0000 !important;
  font-family: 'Red Hat Display Bold' !important;
  height: 34px !important;
  border-radius: 2px !important;
  margin: 0 0px 0 25px !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
}
.orangeButton:hover {
  background: #bf0000 !important;
}

.whiteButton {
  background: #ffffff !important;
  font-family: 'Red Hat Display Bold' !important;
  height: 34px !important;
  border-radius: 2px !important;
  margin: 0 0px 0 25px !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  color: #005ebf !important;
}
.whiteButton:hover {
  background: #ffffff !important;
}
.d-flex-a {
  display: flex;
  align-items: center;
}
.flex-sb {
  justify-content: space-between !important;
}
.flex-sa {
  justify-content: space-around !important;
}
.d-flex-ja {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-jae {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.resume-ipld-gap {
  gap: 56.43px;
}
.modalBackdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.14) !important;
  backdrop-filter: blur(3.5px) !important;
}
.uploadBtnCommon {
  // font-family: Red Hat Display SemiBold !important;
  // font-style: normal;
  // font-size: 12px;
  // line-height: 16px;
  font-family: Red Hat Display Medium !important;
  font-size: 15px !important;
  letter-spacing: -0.25px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  color: #ffffff;
}
.UploadBtnCommonStyle {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #0262b9;
  border-radius: 20px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  // height: 28px;
  height: 40px;
  padding: 6.5px 10px;
}
.uploadIconStyle {
  height: 15px;
}
.UploadPlaceholderStyle {
  font-family: Red Hat Display Medium !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #d9d9d9 !important;
}
.UploadResumeinnerbox {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  display: flex;
  flex-direction: column;
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .heading {
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 33px;
      /* identical to box height */

      color: #000000;
    }
    .closebtn {
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 9px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .customertitle {
    border-bottom: 1px solid #bf0000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: baseline;
    .company {
      // padding-right: 59px;
      display: flex;
      align-items: center;
      align-items: first baseline;
      justify-content: space-between;
      gap: 8px;
      .title {
        min-width: fit-content;
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */

        color: #bdbdbd;
        text-transform: capitalize;
      }
      .value {
        text-transform: capitalize;
        min-width: 143px;
        min-width: 168px;
        font-family: Red Hat Display Bold !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        color: #bf0000;
      }
      // &:nth-child(2) {
      //   .value {
      //     min-width: 168px;
      //   }
      // }
    }
  }
  main {
    .dropdowntitle {
      font-family: Red Hat Display Bold !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #bdbdbd;
      margin-bottom: 6px;
    }
    .dropdownbox {
      .MuiSelect-icon {
        margin-right: 12px;
        margin-top: 2px;
      }
    }
    .UploadResumeSuccess {
      margin-top: 129.7px;
      padding: 0px 48px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .resumesvg {
        width: 235px;
        height: 206px;
      }
    }
    .successTitle {
      margin-top: 18px;
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 33px;
      /* identical to box height */

      color: #bf0000;
    }
    .successDescription {
      margin-top: 8px;
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;

      color: #000000;
    }
  }

  footer {
    // margin-top: auto;
    // margin-left: auto;
    // position: absolute;
    // bottom: 60px;
    // right: 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 5px;
    margin-right: 9px;
    .button {
      /* Frame 3 */
      width: 110px;
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 40px;
      gap: 10px;
      background: #0262b9;
      border-radius: 4px;
      font-family: Red Hat Display SemiBold !important;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #ffffff;
    }
    .buttonUp {
      width: 120px;
    }
  }
}
.UploadResumeinnerboxAction {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    .heading {
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 33px;
      /* identical to box height */

      color: #000000;
    }
    .closebtn {
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 9px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .ActionBody {
    width: 100%;
    .txt {
      text-align: center;
    }
    .dustbinTxt {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18px;
      .txt {
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
        text-align: center;

        color: #000000;
      }
    }
    .mainBtn {
      margin-top: 18px;
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 18px;
      .btn {
        /* Discard Resume */
        cursor: pointer;
        font-family: Red Hat Display SemiBold !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        border-radius: 4px;
      }
      .discard {
        padding: 9px 31px;
        /* identical to box height */

        color: #656565;
        /* Frame 47 */

        box-sizing: border-box;

        background: #ffffff;
        border: 1px solid #0262b9;
        border-radius: 4px;
      }
      .upload {
        padding: 10px 40px;
        background-color: #0262b9;
        color: #ffffff;
      }
    }
  }
  .customertitle {
    border-bottom: 1px solid #bf0000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: baseline;
    .company {
      // padding-right: 59px;
      display: flex;
      align-items: center;
      align-items: first baseline;
      justify-content: space-between;
      gap: 8px;
      .title {
        min-width: fit-content;
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */

        color: #bdbdbd;
        text-transform: capitalize;
      }
      .value {
        text-transform: capitalize;
        min-width: 143px;
        min-width: 168px;
        font-family: Red Hat Display Bold !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        color: #bf0000;
      }
      // &:nth-child(2) {
      //   .value {
      //     min-width: 168px;
      //   }
      // }
    }
  }
  main {
    .dropdowntitle {
      font-family: Red Hat Display Bold !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #bdbdbd;
      margin-bottom: 6px;
    }
    .dropdownbox {
      .MuiSelect-icon {
        margin-right: 12px;
        margin-top: 2px;
      }
    }
    .UploadResumeSuccess {
      margin-top: 129.7px;
      padding: 0px 48px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .resumesvg {
        width: 235px;
        height: 206px;
      }
    }
    .successTitle {
      margin-top: 18px;
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 33px;
      /* identical to box height */

      color: #bf0000;
    }
    .successDescription {
      margin-top: 8px;
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;

      color: #000000;
    }
  }

  footer {
    // margin-top: auto;
    // margin-left: auto;
    // position: absolute;
    // bottom: 60px;
    // right: 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 5px;
    margin-right: 9px;
    .button {
      /* Frame 3 */
      width: 110px;
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 40px;
      gap: 10px;
      background: #0262b9;
      border-radius: 4px;
      font-family: Red Hat Display SemiBold !important;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #ffffff;
    }
    .buttonUp {
      width: 120px;
    }
  }
}
.Dropzone {
  cursor: pointer;
  margin-top: 37px;
  flex-grow: 1;
  width: 100%;
  // margin-left: 32px;
  border: 1.5px dashed #bdbdbd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;

    max-width: 219px;
    margin-left: 15px;
    margin-right: 15px;
    .dropsvg {
      width: 100%;
      & > input {
        height: 0px !important;
        opacity: 0;
      }
    }
    .dropzonesvg {
      margin-top: 35px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-con {
      position: relative;
      z-index: 1;
      text-align: center;
      pointer-events: none;
      .or {
        margin-bottom: 10px;
        font-family: Red Hat Display SemiBold !important;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 9.81331px;
        line-height: 13px;
        /* identical to box height */

        color: #000000;
      }
      .or-below-text {
        margin-bottom: 19px;
        margin-bottom: 9px;
        font-family: Red Hat Display Regular !important;
        font-style: normal;
        font-weight: 400;
        font-size: 9.05844px;
        line-height: 12px;
        /* identical to box height */

        color: rgba(0, 0, 0, 0.4);
      }
      .Upload-button {
        /* Frame 335 */
        margin-bottom: 16px;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:active {
          opacity: 0.6;
          /* transition: opacity 2s ease-in-out; */
        }
        .txt {
          /* Doc Ext */
          /* Select file */
          border-radius: 3.77px;
          border: 0.75px solid #0f91d2b2;
          padding: 9.06px 12.08px;
          font-family: Red Hat Display Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 13px;
          text-transform: uppercase;

          color: #0f91d2;

          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
    }
    .footer {
      width: 100%;
      .txt {
        font-family: 'Creato Display 400';
        font-style: normal;
        font-size: 12px;
        line-height: 21px;
        /* or 175% */
        text-align: center;

        color: #979797;
      }
    }
    .uploadedContainer {
      width: 100%;
      margin-top: auto;
      .profile {
        margin-top: 19px;
        width: 100%;
        text-align: center;
      }
      .uploadedTitle {
        font-family: 'Creato Display 500';
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        // margin-bottom: 12px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        // padding: 10px;
        li {
          display: flex;
          align-items: center;
          column-gap: 4px;
          /* Description */

          /* Auto layout */
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 9.06px; /* Lauraanderson_Resume_Foundit.pdf */

          width: 149px;
          height: 12px;

          font-family: Red Hat Display Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 9.05844px;
          line-height: 12px;
          /* identical to box height */

          color: #bf0000;
          background: #ffffff;

          /* or 175% */
          text-align: center;
          .remove {
            display: inline-block;
            width: 10px;
            height: 10px;
            padding: 2px;
            color: red;
            margin-left: 2px;
            border-radius: 50%;
            border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 8px;
          }
        }
      }
    }
  }
}
.Readyforreviewinnerbox {
  box-shadow: 0px 4px 4px 0px #00000040 !important;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .heading {
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 33px;
      /* identical to box height */

      color: #000000;
      .reviewicon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .closebtn {
      cursor: pointer;
    }
  }
  .customertitle {
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid #bf0000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .company {
      padding-right: 59px;
      display: flex;
      align-items: center;
      align-items: first baseline;
      gap: 8px;
      .title {
        min-width: fit-content;
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */

        color: #bdbdbd;
      }
      .value {
        min-width: 143px;
        font-family: Red Hat Display Bold !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        color: #bf0000;
      }
    }
  }
  main {
    width: 100%;
    padding: 137.5px 10px 0px 10px;
    .dashedbox {
      padding: 0px 0px 0px 0px;
      background: #ffffff;
      border: 1.5px dashed #bdbdbd;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .icon {
        margin-bottom: 10px;
      }
      .btnContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */
          text-decoration-line: underline;

          color: #6ab8ff;
        }
      }

      .para {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .txt {
          max-width: 394px;
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          text-align: center;

          color: #000000;
        }
      }
      .mainBtn {
        margin-top: 18px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
        .btn {
          /* Discard Resume */
          cursor: pointer;
          font-family: Red Hat Display SemiBold !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          border-radius: 4px;
        }
        .discard {
          padding: 9px 31px;
          /* identical to box height */

          color: #656565;
          /* Frame 47 */

          box-sizing: border-box;

          background: #ffffff;
          border: 1px solid #0262b9;
          border-radius: 4px;
        }
        .upload {
          padding: 10px 40px;
          background-color: #0262b9;
          color: #ffffff;
        }
      }
      .DustbinIcon {
        margin-bottom: 25px;
      }
      .dustbinTxt {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
        .txt {
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          /* identical to box height */
          text-align: center;

          color: #000000;
        }
      }
    }
  }
}
.res-val-checkbox {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: -5px !important;
}
.match-badge {
  background-color: rgba(3, 35, 189, 0.25) !important;
  color: #0323bd !important;
  text-transform: uppercase !important;
}
.post-badge {
  background-color: rgb(247 224 225) !important;
  color: #ef6f75 !important;
  text-transform: uppercase !important;
}
.tick-badge {
  img {
    margin-right: 2px !important;
  }
  text-transform: uppercase !important;
  background-color: rgba(128, 189, 1, 0.25) !important;
  color: #80bd03 !important;
}
.btn-red {
  background-color: #ff0000 !important;
}
.header-tab-btn-active {
  height: 32px;
  min-width: 49px !important;
  border-radius: 16px !important;
  background-color: #7fbc01 !important;
  font-size: 14px !important;
  font-family: Red Hat Display Medium !important;
  color: #ffffff !important;
  line-height: 18px !important;
  margin-right: 2px !important;
  padding: 0 10px !important;
  text-transform: capitalize !important;
}
.header-tab-btn-normal {
  height: 32px;
  min-width: 49px !important;
  border-radius: 16px !important;
  background-color: none !important;
  font-size: 14px !important;
  padding: unset !important;
  font-family: Red Hat Display Medium !important;
  color: #727272 !important;
  line-height: 18px !important;
  margin-right: 2px !important;
  text-transform: capitalize !important;
  padding: 0 10px !important;
}
.header-filter-box {
  height: 49px;
  min-width: 49px !important;
  border: 1px solid #d7d7d7 !important;
  border-radius: 4px !important;
  margin-right: 9px !important;
}
.header-add-btn {
  height: 47px;
  min-width: 154px !important;
  background-color: #0262b9 !important;
  box-shadow: 0 1px 2px 0 rgba(44, 24, 81, 0.35);
  color: #ffffff !important;
  font-family: Red Hat Display Medium !important;
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 20px;
  text-transform: capitalize !important;
}
.header-company-btn {
  color: #000000 !important;
  background-color: transparent !important;
  font-family: Red Hat Display SemiBold !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  border-color: transparent !important;
}
::-ms-reveal {
  display: none !important;
}
.vertical-bar {
  border-left: 1px solid #d6d6d6 !important;
}
/*---------------------------Device responsive styles----------------------*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  // .recruiter-body {
  //   padding: 43px 140px 0px 140px !important;
  // }
  .job-view-body {
    padding: 40px 0px !important;
  }
}
@media only screen and (min-height: 600px) {
  .login-area {
    position: absolute;
    bottom: 35px;
    width: 100%;
  }
}
@media only screen and (max-width: 899px) {
  .headernav {
    font-size: 11px !important;
  }
  .filterlist {
    button {
      font-size: 11px !important;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .timesheet-details input {
    width: 85% !important;
  }
  .timesheet-details tbody th {
    width: 30%;
  }
  .timesheet-details-admin tbody th {
    width: 38%;
  }
  .timesheet-details-admin input {
    width: 100% !important;
  }
}

.MuiDayPicker-weekContainer .Mui-disabled {
  color: grey !important;
}
.pg-viewer-wrapper {
  overflow-y: hidden !important;
}
.common-textarea-timereq {
  background: #f8f8fa;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  border-bottom: 1px solid #e8e8e8;
  .MuiInputBase-multiline {
    padding: 0;
  }
  textarea {
    font-family: Red Hat Display SemiBold !important;
    font-size: 14px !important;
    color: black !important;
    height: 120px !important;
    border: 0px solid transparent !important;
    margin: 0 !important;
  }
}

.width-100 {
  width: 100% !important;
}
.width-95 {
  width: 95% !important;
}
.currencyLabel {
  background-color: inherit !important;
  height: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #292929 !important;
  font-size: 14px !important;
  font-family: 'Red Hat Display Bold' !important;
  border: 0px !important;
  width: 60px !important;
  border-radius: 0px !important;
}
.currencyLabel:hover {
  border: 0px !important;
}
.myLabelEditTableClass {
  color: #292929 !important;
  font-size: 13px !important;
  font-style: normal !important;
  display: grid !important;
  font-family: 'Red Hat Display Medium' !important;
}
.myInputEditTableClass {
  color: #292929 !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-family: 'Red Hat Display Medium' !important;
  border: 0px !important;
  background-color: #f7f7f7 !important;
  width: inherit;
  height: 30px !important;
}
.myLabelEditTableClass1 {
  color: white !important;
  font-size: 13px !important;
  font-style: normal !important;
  display: grid !important;
  font-family: 'Red Hat Display Medium' !important;
}

.react-tel-input .form-control {
  border: 0px solid #cacaca !important;
  border: unset !important;
  height: 44px !important;
  border-radius: unset !important;
  background-color: #f3f3f3 !important;
}
.errorMsg {
  color: #da5a30 !important;
  font-weight: 400 !important;
  line-height: 1.66 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 13px !important;
  margin-left: 0px !important;
}
.common-select {
  background: #f3f3f3 !important;
  display: flex !important;
  height: 46px !important;
  & .MuiSelect-select {
    font-family: 'Red Hat Display SemiBold' !important;
    font-size: 16px !important;
    color: #292929 !important;
  }
}

.common-select-table {
  width: 100%;
  background: transparent !important;
  // display: flex !important;
  .MuiSelect-icon {
    right: 0 !important;
  }
  & .MuiSelect-select {
    color: #bdbdbd !important;
    font-size: 12px !important;
    font-family: 'Red Hat Display Bold' !important;
    line-height: 16px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    padding: 0px !important;
    // padding-right: 20px !important;
  }
  & span {
    color: #bdbdbd !important;
    font-size: 12px !important;
    font-family: 'Red Hat Display Bold' !important;
    line-height: 16px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
    padding: 0px !important;
    // padding-right: 20px !important;
  }
}

tr {
  text-transform: capitalize !important;
}

.more-buttom {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #d62129;
}
.show-more-box {
  background-color: #ffffff;
  border: 1px solid;
  max-width: 400px;
  padding: 9px;
  border-color: #ededed;
  border-radius: 8px;
  color: #000000 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  text-transform: capitalize;
}
.hysiap {
  overflow-y: unset !important;
}
.pg-viewer-wrapper {
  background: #efefef;
  overflow: scroll !important;
  height: 75vh;
  width: 100%;
}
.pg-viewer-wrapper .document-container {
  width: 100% !important;
}
.skill-job-select {
  input {
    height: 44px !important;
    border-radius: 0px !important;
    background-color: #f3f3f3 !important;
    border: 0px !important;
    padding-right: 32px !important;
  }
  .MuiAutocomplete-inputRoot {
    padding: 0px !important;
  }
  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }
}

.pdf-loading {
  display: none !important;
}

.ck-editor__editable_inline {
  min-height: 150px; /* Adjust this value as needed */
}

.btn-disabled {
  opacity: 0.6;
  filter: grayscale(100%);
  cursor: none !important;
  &:hover {
    box-shadow: none !important;
  }
}

.sync-loader {
  transform-origin: center;
}

.sync-anim {
  animation: syncRotate 0.9s infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes syncRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.TagsInputStyleCon {
  display: flex;
  align-items: center;
  .trashicon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
  .TagsInputStyleConInner {
    .tagserror {
      color: #da5a30 !important;
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 13px !important;
    }
  }

  .TagsInputStyle {
    height: 29px;
    // border: 1px solid #e0e0e0 !important;
    border-color: transparent;
    background-color: unset !important;
    // border-radius: 6px !important;
    height: 29px !important;
    padding: 3.5px 14px 3.5px 12px !important;
    width: 100%;
    font-size: 12px !important;
    // overflow: auto;
    // scrollbar-width: thin; /* Options: auto, thin */
    // scrollbar-color: #888 #f1f1f1;

    .ReactTags__tags {
      position: relative;
    }

    .ReactTags__clearAll {
      display: none;
      cursor: pointer;
      padding: 10px;
      margin: 10px;
      background: #f88d8d;
      color: #fff;
      border: none;
    }

    .ReactTags__tagInput {
      border-radius: 2px;
      display: inline-block;
      display: none;
      input.ReactTags__tagInputField {
        height: 32px;
        margin: 0;
        padding: 5px;
        font-size: 14px;
        border: 1px solid #eee;
        min-width: 180px;

        &:focus {
          height: 32px;
          margin: 0;
          padding: 5px;
          font-size: 14px;
          border: 1px solid #eee;
          min-width: 180px;
        }
      }
    }

    .ReactTags__editInput {
      border-radius: 1px;
    }

    .ReactTags__editTagInput {
      display: inline-flex;
    }

    .ReactTags__selected {
      // overflow-x: scroll;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      // flex-wrap: nowrap;
      gap: 4px;
      // overflow: scroll;
      span.ReactTags__tag {
        flex-shrink: 0;
        cursor: pointer !important;
        color: #000000;
        padding: 8px;
        // margin: 0 5px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 19px;
        background: #f8f8fa;
        border-radius: 8px;
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        // font-size: 14px;
        font-size: 12px;
        line-height: 19px;
        /* identical to box height */
        color: #000000;
      }

      .ReactTags__remove {
        color: #000000 !important;
        cursor: pointer;
        display: flex;
        svg {
          fill: #000000 !important;
          width: 9px;
          height: 9px;
        }
      }
    }

    .ReactTags__suggestions {
      position: absolute;

      ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        background: white;
        width: 200px;
        padding: 5px 0;
      }

      li {
        padding: 5px 10px;
        margin: 0;
        text-align: left;

        mark {
          text-decoration: underline;
          background: none;
        }

        &.ReactTags__activeSuggestion {
          background: #4dabf7;
          color: #fff;
          cursor: pointer;

          span mark {
            color: #fff;
          }
        }
      }
    }

    .ReactTags__remove {
      border: none;
      cursor: pointer;
      background: none;
      color: #fff;
      padding: 5px;
    }

    .ReactTags__error {
      color: #e03131;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        padding: 0 5px;
      }
    }
  }

  // .ReactTags__tags.react-tags-wrapper {
  //   overflow: hidden;
  // }
}

.TagsInputStyleCon .nonTagWidth {
  width: calc(100% - 30.99px);
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 8px;
}

.projectskillcon {
  overflow: auto;
  scrollbar-width: thin; /* Options: auto, thin */
  scrollbar-color: #888 #f1f1f1;
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 29px !important;
}

.Analyticalpopup {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  z-index: 120 !important;
  .Popupcontent {
    width: 446px;
    height: 179px;

    border: 1px solid #7fbc01;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 16px 21px 19px;
    z-index: 3;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;
      .closesvg {
        cursor: pointer !important;
        z-index: 3;
      }
      .txt {
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 19px;
        /* identical to box height */

        color: #000000;
      }
    }
    main {
      height: 112px;
      overflow: auto;
      font-family: Red Hat Display Medium !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px;

      color: #000000;
    }
  }
}
.projectskillconDisable {
  pointer-events: none !important;
}

.red-asterisk {
  color: #bf0000 !important;
}

.REVIEWED {
  background-color: #6ab8ff !important;
  color: #ffffff !important;
}
.INPROGRESS {
  background-color: #fcec11 !important;
  color: #000 !important;
}
.ACCEPTED-RFR {
  background-color: #f2ffd8 !important;
  color: #7fbc01 !important;
}
.READYFORREVIEW {
  background-color: #f2ffd8 !important;
  color: #7fbc01 !important;
}
.REJECTED {
  background-color: #bf0000 !important;
  color: #ffffff !important;
}
.DISCARDED {
  background-color: #f5f5f5 !important;
  color: #bdbdbd !important;
}
.PARSINGFAILED {
  background-color: #f2ffd8 !important;
  color: #ff0000 !important;
}
.table-default-clrs {
  background-color: #ff0000 !important;
  color: #ffffff !important;
}

.table-parsefailed-align {
  display: flex !important;
  justify-content: space-evenly !important;
}
.table-discarded-align {
  column-gap: 5% !important;
  display: flex !important;
  justify-content: center !important;
}

.table-cur-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}
.table-rfr-minWidth {
  min-width: 181.75px !important;
}

.table-width-auto {
  width: auto !important;
}

.close-filter {
  cursor: pointer;
  height: 18.23px;
  width: 18px;
}

.red-filter {
  height: 18px;
  width: 30px;
}

.pd-left-12 {
  padding-left: 12px !important;
}

.resval-table-status {
  border-radius: 20px !important;
  font-family: Red Hat Display SemiBold !important;
  font-size: 15px !important;
  padding: 6px !important;
  text-align: center !important;
  text-transform: unset !important;
}

.pdf-nofile {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.pdf-nofile-text {
  background-color: #e53e3e;
  border-radius: 0.25rem;
  color: #fff !important;
  font-family: Red Hat Display Regular !important;
  padding: 0.5rem;
}
