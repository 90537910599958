.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.w-425 {
  width: 425px;
}
.m-auto {
  margin: auto !important;
}
.red-color {
  color: #bf0000 !important;
}
.pt-12 {
  padding-top: 12px !important;
}
.plr-14 {
  padding: 0px 14px !important;
}
.pb-12 {
  padding-bottom: 12px;
}
.pl-17 {
  padding-left: 17px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pt-4 {
  padding-top: 4px;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.g-10 {
  gap: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-14 {
  margin-top: 14px !important;
}
.mb-46 {
  margin-bottom: 46px !important;
}
.mt-19 {
  margin-top: 19px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-7 {
  margin-bottom: 7px !important;
}
.plr-6 {
  padding: 0px 6px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.panel-resize-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb; // equivalent to Tailwind's bg-gray-300
  color: #4b5563; // equivalent to Tailwind's text-gray-600
  width: 15px;
  .handle-bar {
    cursor: pointer;
    width: 28px;
    z-index: 1;
  }
}
#right-panel {
  padding-left: 2px;
}
.cursor-disable {
  opacity: 0.2;
  cursor: not-allowed !important;
}
.font-bold {
  font-family: 'Red Hat Display Bold' !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px !important;
}
.not-allowed {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.error-matching-profile {
  display: block;
  margin-bottom: 6px;
}
.black-color {
  color: #434343 !important;
}
.semi-bold {
  font-family: Red Hat Display SemiBold !important;
}
.react-tel-input {
  .custom-phone-input {
    border: 1px solid #cacaca !important;
    height: 29px !important;
    border-radius: unset !important;
    background-color: #ffff !important;
    border-radius: 6px !important;
    font-size: 12px !important;
  }
}
.custom-datepicker {
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px !important;
  height: 30px !important;
  width: 100% !important;
  background-color: #ffff !important;
  font-size: 12px !important;
}
.react-pdf__message--error {
  color: #bf0000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
}

.react-pdf__message--loading {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
}
.resume-modal {
  outline: none !important;
  background-color: #ffffff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  left: 50%;
  padding: 22px 10px 15px 20px;
  position: absolute;
  top: 0%;
  transform: translate(-50%, 0%);
  width: 100%;

  padding-bottom: 2px !important;
  bottom: 0px;
  &.view-resume {
    width: 70% !important;
    .pdf-page-container {
      box-shadow: unset !important;
      height: 98vh !important;
      // canvas {
      //   width: 100% !important;
      //   height: 100vh !important;
      // }
    }
    .pdf-header-resume {
      width: 100% !important;
      right: 43px !important;
    }
  }
  .icon-postition {
    position: absolute;
    right: 25px;
  }
  .icon-size {
    color: #bf0000 !important;
    font-size: 32px !important;
  }
  .resume-header-name {
    font-family: 'Red Hat Display Medium' !important;
    font-weight: 500 !important;
    font-size: 24px !important;
  }
  .pdf-header-resume {
    display: flex;
    justify-content: flex-end;
    // width: 90%;
    width: 100%;
    align-items: center;
    position: absolute;
    // top: -51px;
    // top: -60px;
    top: -42px;
    border-radius: 4%;
    z-index: 9999999;
    .pdf-button-container {
      font-family: 'Red Hat Display Bold' !important;
      gap: unset !important;
      color: #bf0000;
      font-weight: 700;
      font-size: 12px;
      .download-btn {
        color: #0a84ff;
        font-family: Red Hat Display Bold !important;
        font-size: 15px !important;
        -webkit-text-decoration: underline;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .pdf-app-views {
    justify-content: unset !important;
    height: unset !important;
    .pdf-page-container {
      margin: 0px 20px !important;
      height: 90vh;
      canvas {
        margin: auto !important;
      }
    }
  }

  .resume-tab-header {
    font-size: 12px !important;
    padding-bottom: 15px;
    padding-left: 23px;
    // font-family: 'Red Hat Display SemiBold' !important;
    cursor: pointer;
  }
  .highlight {
    text-decoration: underline;
    text-underline-offset: 7px;
    text-decoration-line: underline;
    text-decoration-thickness: 6px;
  }

  .resume-search-header {
    font-size: 12px !important;
    color: #bdbdbd !important;
    font-weight: 600 !important;
  }

  .count {
    padding-left: 12px;
    font-weight: 600;
  }
  .custom-search {
    width: 100% !important;
    .MuiInputLabel-root {
      font-family: 'Red Hat Display Regular' !important;
    }
    .MuiOutlinedInput-input {
      background-color: #f3f3f3 !important;
      border: 1.5px solid #bf0000 !important;
      border-radius: 6px !important;
      height: 44px !important;
      padding: 3.5px 14px 3.5px 12px !important;
      width: 100% !important;
    }
  }
  .custom-Input {
    width: 100% !important;
    .MuiInputLabel-root {
      font-family: 'Red Hat Display Regular' !important;
    }
    .MuiOutlinedInput-input {
      background-color: #ffffff !important;
      border-radius: 6px !important;
      height: 29px !important;
      padding: 3.5px 14px 3.5px 12px !important;
      width: 100% !important;
      font-size: 12px !important;
    }
    .Mui-disabled {
      color: #161719 !important;
      -webkit-text-fill-color: #161719 !important;
    }
  }

  .flex-align-lable {
    align-items: flex-end;
    display: flex;
    min-height: 18px !important;
  }
  .custom-Input-1 {
    width: 100% !important;
    .MuiInputLabel-root {
      font-family: 'Red Hat Display Regular' !important;
    }
    .MuiOutlinedInput-root {
      padding-right: unset !important;
    }
    .MuiOutlinedInput-input {
      background-color: unset !important;
      border-radius: 6px !important;
      height: 29px !important;
      padding: 3.5px 14px 3.5px 12px !important;
      width: 100% !important;
      font-size: 12px !important;
      padding-right: 31px !important;
    }
    .Mui-disabled {
      color: #161719 !important;
      -webkit-text-fill-color: #161719 !important;
    }
    .iconplacement {
      position: absolute !important;
      right: 0px !important;
    }
  }

  .list-header {
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: 'Red Hat Display SemiBold' !important;
  }
  .body-hight {
    // height: 55vh;
    height: 75vh;
    overflow: auto;
    padding-right: 10px;
  }
  .body-hight2 {
    // height: 55vh;
    height: calc(87vh - 110px);
    overflow: auto;
    padding-right: 10px;
  }
  .body-hight3 {
    // height: 55vh;
    margin-bottom: 20px;
    margin-top: auto;
    height: calc(87vh - 210px);
    overflow: auto;
    padding-right: 10px;
  }
  .footer-info {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    padding: 14px;
    padding-top: 18px !important;
    .other {
      font-size: 14px !important;
      color: #bdbdbd !important;
    }
    .resume-btn-footer {
      border: 1px solid #a0a0ef;
      border-radius: 5px;
      padding: 7px 31px;
      max-width: 190px;
      height: 33px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      background-color: #0262b9 !important;
      cursor: pointer;
    }
  }
  .g-7 {
    gap: 7px;
  }
  .notes-card-info {
    border: 0.5px solid green !important;
    border-radius: 25px !important;
    width: 100%;
    min-height: 120px;
    padding: 11px 20px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    .info {
      font-size: 12.5px;
      line-height: normal;
      font-family: 'Red Hat Display SemiBold' !important;
      padding-bottom: 2px;
    }
    .footer-info {
      border-top: 1px solid #bdbdbd;
      padding: unset !important;
      padding-top: 8px !important;
    }
  }
  .card-info {
    border: 1px solid #00000024;
    width: 100%;
    min-height: 146px;
    box-shadow: 0 2px 4px 0 rgba(62, 62, 62, 0.15);
    display: flex;
    gap: 12px;
    padding: 11px 20px;
    flex-direction: column;
    position: relative;
    &:hover {
      border: 1px solid #bf0000 !important;
    }
    .g-35 {
      gap: 35px;
    }

    .Value {
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 14px !important;
      font-weight: 500;
    }

    .view-resume {
      color: #0262b9 !important;
      -webkit-text-decoration: underline solid #0262b9 !important;
      text-decoration: underline solid #0262b9 !important;
      font-family: Red Hat Display SemiBold !important;
      font-size: 12px !important;
      letter-spacing: 0;
      line-height: 16px !important;
      cursor: pointer;
    }
  }

  .custom-textarea {
    textarea {
      height: 80px !important;
    }
    .MuiInputBase-root {
      height: 91px !important;
      padding: 15px 18px 18px 17px !important;
      border-radius: 16px !important;
      border: 1.5px solid #e0e0e0 !important;
    }
    .MuiInputLabel-root {
      font-family: 'Red Hat Display Regular' !important;
      line-height: unset !important;
    }
    .MuiOutlinedInput-input {
      background-color: #ffff !important;
      border: unset !important;
      border-radius: unset !important;
      padding: 1.5px 3px 1.5px 2px !important;
      font-size: 12px !important;
    }
    .MuiInputBase-formControl .Mui-disabled {
      color: #161719 !important;
      -webkit-text-fill-color: #161719 !important;
    }
    width: 100%;
  }

  .common-select {
    background: unset !important;
    display: flex !important;
    height: 29px !important;
    border: 1px solid #e0e0e0 !important;
    & .MuiSelect-select {
      background-color: unset !important;
      border-radius: 6px !important;
      padding: 3.5px 14px 3.5px 12px !important;
      width: 100% !important;
      font-size: 12px !important;
    }
  }
}
.btn-update-resume {
  border: 1px solid #a0a0ef;
  border-radius: 5px;
  // height: 33px;
  padding: 6px 15px;
  max-width: 162px;
  font-size: 12px !important;
  text-align: center;
  // position: absolute;
  // bottom: 10px;
  // right: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(62, 62, 62, 0.15);
  }
  .plus {
    font-size: 25px;
  }
}
.add-notes {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 0px 11px;
  font-size: 12px !important;
  padding-right: 15px;
}
.title {
  font-size: 14px !important;
  color: #aba9a9 !important;
  font-family: 'Red Hat Display SemiBold' !important;
}

.ad-btn-notes {
  width: 85px !important;
  height: auto !important;
  padding: 1px 0px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  cursor: pointer !important;
  border: unset !important;
}
.cancel-btn-notes {
  background-color: #ffffff !important;
  border: 1px solid #0262b9 !important;
  color: #000000a6 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  width: 85px !important;
  height: auto !important;
  padding: 1px 0px !important;
}

.accordion-heading {
  font-size: 14px !important;
  font-family: 'Red Hat Display SemiBold' !important;
  color: #ffff !important;
}

.accordion-root {
  &.MuiAccordion-root {
    border-radius: 25px !important;
    box-shadow: none !important;
    border: unset !important;
    position: unset !important;
    color: #ffffff !important;
    margin-top: -2px;
  }
  &.Mui-expanded {
    margin: 0px 0 !important;
  }
  .MuiAccordionSummary-root {
    margin: 2px 0px !important;
    background-color: #6ab8ff !important;
    min-height: unset !important;
    .MuiAccordionSummary-content {
      margin: 6px 0 !important;
    }
  }
  .MuiAccordionDetails-root {
    padding: 8px 16px 8px !important;
  }
  .icon-color {
    color: #ffffff !important;
    font-weight: 700 !important;
  }
  .input-title {
    font-size: 12px !important;
    color: #888888 !important;
    font-family: 'Red Hat Display Bold' !important;
    padding-left: 5px !important;
    display: block !important;
    overflow: hidden !important;
    // padding-bottom: 4px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  .input-title {
    font-size: 12px !important;
    color: #888888 !important;
    font-family: 'Red Hat Display Bold' !important;
    padding-left: 5px !important;
    display: block !important;
    overflow: hidden !important;
    // padding-bottom: 4px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}
.input-title-popup {
  font-size: 12px !important;
  color: #888888 !important;
  font-family: 'Red Hat Display Bold' !important;
  padding-left: 5px !important;
  display: block !important;
  overflow: hidden !important;
  // padding-bottom: 4px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.popup-tags {
  background-color: #ffffff !important;
}
.TagsInputStyleCon .TagsInputStyle {
  .react-tags-wrapper {
    background-color: #ffffff !important;
  }
  padding: unset !important;
}
.don-btn {
  width: 92px !important;
  height: 31px !important;
}
.skill {
  max-width: 123px !important;
  color: #2c2ce7 !important;
  font-family: 'Red Hat Display Medium' !important;
}

.AnalyticalDetailscontainer2 {
  padding: 6px 26px 7px 10px !important;
  border: 1px solid #bdbdbd !important;
  border-color: transparent !important;
  border-radius: 20px;
  margin-top: 16px;
  &:nth-child(1) {
    margin-top: 0px;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .score {
      font-family: Red Hat Display Medium !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      color: #7fbc01;
    }
  }
  .infocontainer {
    display: none !important;
    margin: 0px !important;
  }
}
.AnalyticalDetailscontainer {
  padding: 0px 8px 16px 10px;
  border: 1px solid #bdbdbd;
  border-color: transparent !important;
  border-radius: 20px;
  // margin-top: 16px;
  &:nth-child(1) {
    margin-top: 0px;
  }
  .heading {
    font-family: Red Hat Display Bold !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #bdbdbd;
  }
  .infocontainer {
    width: 100%;
    margin-top: 8px;
    .infocontentBox {
      cursor: pointer;
      margin-top: 7px;

      &:nth-child(1) {
        margin-top: 0px;
      }
      border-radius: 20px !important;
      width: 100%;

      padding: 4px 2.5px 3.33px 19px;

      .title {
        cursor: pointer;
        font-family: Red Hat Display Medium !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
        color: #000000;
        text-transform: capitalize;
      }
      .titleActiv {
        font-family: Red Hat Display Bold !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        /* identical to box height */

        color: #0262b9 !important;
      }
      .flagContainer {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        .flagIcon {
          width: 12px;
          height: 14.67px;
        }
        .link {
          cursor: pointer;
          text-underline-offset: 4px;
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */
          text-decoration-line: underline;

          color: #6ab8ff;
          text-decoration: underline;
          text-decoration-thickness: 2px;
        }
        .flagtxt {
          padding: 4px 8px 3px 9px;
          background: #d6ecff;
          border-radius: 16px 20px 20px 16px;
          font-family: Red Hat Display Medium !important;
          font-style: normal !important;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #0262b9;
          min-width: 60px;
          text-align: center;
        }
        .flagtxt2 {
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          /* identical to box height */
          color: #6ab8ff;
        }
      }
    }
    .subbox {
      margin-top: 8px;
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px 7.5px;

      .infocontentBox {
        // height: 32px;
        flex-basis: 49%;
        margin-top: 0px !important;
      }
    }

    .history {
      margin-top: 8px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      gap: 8px 7.5px;
      .infoHistoryBox {
        flex-basis: 49%;
        margin-top: 0px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        row-gap: 11px;
        padding-bottom: 11px !important;
        .infoheader {
          justify-content: center !important;
        }
        .label {
          font-family: Red Hat Display Bold !important;
          font-style: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */
          text-align: center !important;
          color: #bdbdbd;
        }
        .title {
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px !important;
          line-height: 19px;
          /* identical to box height */
          text-align: center !important;
          color: #000000;
        }
      }
    }
  }
}
.grammar {
  padding: 0px 8px 18px 10px;
  margin: 0px 8px 18px 10px;
  border: 1px solid #bdbdbd;
  border-radius: 20px;
  margin-top: 0px;
  padding: 7px 2.5px 7.33px 19px;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .headingGram {
    font-family: Red Hat Display SemiBold !important;
    font-style: normal !important;
    font-weight: 700 !important;
    // font-size: 12px !important;
    line-height: 16px !important;
    /* identical to box height */

    color: #888888 !important;
  }
  .headingGramActv {
    color: #0262b9 !important;
  }
  .score {
    text-align: center;
    padding: 4px 8px 3px 9px;
    background: #d6ecff;
    border-radius: 16px 20px 20px 16px;

    font-family: Red Hat Display Medium !important;
    font-style: normal !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #0262b9;
    min-width: 60px;
    text-align: center;
  }
}
.BorderGreenAnalytics {
  border: 1px solid #7fbc01 !important;
  border: 1px solid #94cafa !important;

  .title {
    color: #000000 !important;
  }
}
.BorderRedAnalytics {
  border: 1px solid #ff0000 !important;
  border: 1px solid #94cafa !important;

  .title {
    color: #ff0000 !important;
    color: #000000 !important;
  }
}

.Analyticsfooter {
  margin-top: 0px !important;
  display: flex;
  justify-content: space-between;
  padding: 0px 8px 16px 10px;
  .footerFirstBoc {
    .txt {
      min-width: 222px;
      max-width: 262px;
      height: 32px;
      font-family: Red Hat Display Bold !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;

      color: #bdbdbd;
    }
    .choice {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      column-gap: 82px;
      .checkbox {
        column-gap: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .svg {
          width: 16px;
          height: 16px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .txt2 {
          cursor: pointer;
          width: 46px;
          height: 19px;
          font-family: Red Hat Display Medium !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          /* identical to box height */

          color: #000000;
        }
      }
    }
  }
  .btn {
    /* Frame 3 */
    cursor: pointer;
    align-self: flex-end;
    text-align: center;
    padding: 10px 40px;
    gap: 10px;

    width: 110px;
    height: 36px;

    background: #0262b9;
    border-radius: 4px;
    font-family: Red Hat Display SemiBold !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #ffffff;
  }
}
.flag-dropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 9px;
  .flagtxt {
    font-family: Red Hat Display SemiBold !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #656565;
  }
  .flagdropdwn {
    position: relative;
    .dropdown {
      padding: 8.33px 29px 6.67px 9px;
      position: absolute;
      top: 100%;
      left: 0%;
      right: 0px;
      background: #ffffff;
      border: 1px solid #bdbdbd;
      border-radius: 8px;
      .Flags {
        position: relative;
        &:hover {
          &::after {
            content: '';
            position: absolute;
            top: -9px;
            left: -9px;
            right: -29px;
            bottom: -2px;
            background-color: #bdbdbd45;
          }
          .roundcheck {
            &::after {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              box-sizing: border-box;
              border-radius: 50%;
              background-color: #000000;
              top: 23%;
              left: 18%;
            }
          }
        }
        cursor: pointer;
        margin-top: 12.67px;
        &:nth-child(1) {
          margin-top: 0px;
        }
        display: flex;
        // align-items: center;
        // align-content: center;
        column-gap: 6px;
        .flagicon {
          width: 18px;
          height: 22px;
        }
        .roundcheck {
          /* Ellipse 13 */
          transform: translateY(25%);
          width: 10px;
          height: 10px;
          box-sizing: border-box;
          border-radius: 50%;
          border: 1px solid #000000;
          position: relative;
        }
      }
    }
    .icon {
      cursor: pointer;
    }
  }
}

.skil-popup {
  padding: 3px 16px;
  .text-center {
    text-align: center !important;
  }
  .postition-sticky {
    position: sticky;
    background-color: #ffff;
    z-index: 1;
  }
  .top-0 {
    top: 0;
  }
  .bottom-0 {
    bottom: 0;
  }
  .top-45 {
    top: 45px;
  }
  .skill-search {
    input {
      background-color: transparent !important;
      border: unset !important;
      padding-left: unset !important;
      margin: unset !important;
      width: 265px !important;
    }
  }
}
.skill-list-height {
  height: 260px;
  overflow: auto;
}
.reasoncontainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .inputContainer {
    .label {
      font-family: Red Hat Display Bold !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #bdbdbd;
    }
    textarea {
      margin-top: 5px;
      /* Rectangle 41 */

      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #bdbdbd;
      border-radius: 20px;
      padding: 13px 22px 13px 11px;
      width: 100%;
      min-height: 113px;
    }
  }
}
.reasonBtnContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
  .btn {
    cursor: pointer;
    font-family: Red Hat Display SemiBold !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    /* identical to box height */
    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
  .bt2 {
    /* Frame 313434 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 10px;

    background: #0262b9;
    border-radius: 4px;
    /* Submit */

    width: 120px !important;
    height: 36px !important;

    color: #ffffff;
  }
  .bt1 {
    /* Frame 46 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 10px;
    width: 118px;
    height: 36px;
    background: transparent;
    border: 1px solid #0262b9;
    border-radius: 4px;

    font-family: Red Hat Display SemiBold !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #0262b9 !important;
  }
}
.height-164 {
  // height: 164px !important;
  height: 135px !important;
}

.discardbtn {
  /* Frame 46 */
  cursor: pointer;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  gap: 10px;
  width: 122px;
  height: 36px;
  background: transparent;
  border: 1px solid #0262b9;
  border-radius: 4px;
  font-family: Red Hat Display SemiBold !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #0262b9;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

.GeneralDetails {
  /* Frame 3 */
  cursor: pointer;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  gap: 10px;
  width: 120px;
  height: 36px;
  background: #0262b9 !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  font-family: Red Hat Display SemiBold !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.label-figma {
  font-family: Red Hat Display Bold !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  /* identical to box height */

  color: #888888 !important;
}

.discard-reason-label {
  // color: #5f5353 !important;
  color: #362f2f !important;
}

// .rpv-core__minimal-button {
//   background-color: #ffffff !important;
// }

// .rpv-core__minimal-button:hover {
//   background-color: #ffffff !important;
// }

// .rpv-zoom__popover-target-arrow {
//   border-color: var(--rpv-zoom__popover-target-arrow-border-color)
//     rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
//   border-style: solid;
//   border-width: 0.5rem 0.25rem 0;
//   height: 0;
//   width: 0;
// }

.rpv-core__minimal-button {
  // background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  border-radius: 0.25rem !important;
  /* color: var(--rpv-core__minimal-button-color); */
  cursor: pointer !important;
  height: 2rem !important;
  padding: 0 0.5rem !important;
  color: rgb(255, 255, 255) !important;
}

.rpv-zoom__popover-target-arrow {
  /* border-color: var(--rpv-zoom__popover-target-arrow-border-color) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0); */
  border-style: solid !important;
  border-width: 0.5rem 0.25rem 0 !important;
  height: 0 !important;
  width: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(255, 255, 255) !important;
  border-color: white #0262b9 !important;
}
.rpv-core__icon {
  stroke-width: 3px !important;
}

.infoheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.infomain {
  width: 100%;
  overflow: auto;
  margin-top: 2px;
  height: 100px;
  padding-right: 43px;

  font-family: Red Hat Display Medium !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;

  color: #000000 !important;
  .description-line-break {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 18px !important;
    margin-top: 1px;
    li {
      font-family: Red Hat Display Medium !important;
    }
  }
}

.AnalyticsTotalScore {
  display: flex;
  justify-content: center;
  cursor: pointer;
  .txt {
    border-radius: 4px;
    background-color: #0262b9;
    // padding: 1.5px 13px 1.5px 13px;
    font-family: Red Hat Display Bold !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    line-height: 26px;
    color: #ffffff;
    min-width: 58px;
    display: block;
    text-align: center;
    padding: 0px 4px;
  }
}

.ml-score {
  margin-left: auto !important;
}
.core__doc-loading {
  background-color: #f2f2f2 !important;
}
.rpv-core__doc-error {
  background-color: #f2f2f2 !important;
}
.rpv-core__inner-page {
  background-color: #f2f2f2 !important;
}
.rpv-core__page-layer::after {
  box-shadow: unset !important;
}

.save-reject-btn {
  align-self: flex-end;
  text-align: center;
  padding: 10px 40px;
  gap: 10px;

  width: 110px;
  height: 36px;

  background: #0262b9;
  border-radius: 4px;
  font-family: Red Hat Display SemiBold !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
}

.disabled-skill-add-btn {
  background-color: #cccccc !important;
  color: #999999 !important;
  cursor: not-allowed !important;
}
