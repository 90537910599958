.rdrDateDisplayWrapper {
  display: none !important;
}
.rdrDateRangeWrapper {
  body,
  div,
  span,
  label,
  input,
  textarea,
  button,
  select,
  option,
  td,
  th,
  li,
  p {
    font-family: Red Hat Display Medium !important;
  }
}
